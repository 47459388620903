import React, { useEffect } from 'react';
import { withTranslation, initReactI18next } from 'react-i18next';
import { useDidUpdateEffect, useShallowSelector } from 'lib/util';
import { UtilActions } from 'store/reduxActions';
import { useState } from 'react';

const I18nLanguage = React.memo(function I18nLanguage({ i18n }) {
  const { language, translation, translationSuccess } = useShallowSelector(
    state => ({
      language: state.app.language,
      translation: state.utilReducer.translation,
      translationSuccess: state.utilReducer.translation.success,
    }),
  );

  const [i18nResource, setI18nResource] = useState({});

  const [chagneFlag, setChangeFlag] = useState(false);

  // const language = "en";

  useEffect(() => {
    console.log('HDS] language ____________ ', language);
    UtilActions.LANGUAGE_TRANSLATION_REQUEST(language);
  }, [language]);

  useEffect(() => {
    console.log('HDS] translationSuccess ____________ ', translationSuccess);
  }, [translationSuccess]);

  useDidUpdateEffect(() => {
    if (!translationSuccess) return;
    setI18nResource(draft => {
      return {
        ...draft,
        [`${language}`]: { translation: translation.data.data },
      };
    });
  }, [translationSuccess, translation]);

  // useEffect(() => {
  //   setChangeFlag(true);
  // }, [i18nResource]);

  // useEffect(() => {
  //   i18n.changeLanguage(language);
  // }, [language]);

  useEffect(() => {
    console.log('HDS] i18nResource ______ ', i18nResource);
  }, [i18nResource]);
  useEffect(() => {
    // if (!chagneFlag) return;
    if (Object.keys(i18nResource).length === 0) return;

    i18n
      .use(initReactI18next) // passes i18n down to react-i18next
      .init({
        resources: i18nResource,
        lng: language,
        fallbackLng: language,
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
          escapeValue: false, // react already safes from xss
        },
        react: {
          useSuspense: true,
        },
      })
      .then(() => {
        console.log('HDS] i18n 초기화 완료');
      })
      .catch(error => {
        console.error('HDS] i18n 초기화 중 오류 발생: ', error);
      });
    i18n.changeLanguage(language);
    //  setLangState(currLang);
    // setChangeFlag(false);
  }, [language, i18nResource]);

  return null;
});

export default withTranslation()(I18nLanguage);
