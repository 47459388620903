import { createAction, createReducer, createSlice } from '@reduxjs/toolkit';
import CODE from 'lib/codeName';
import { createFetchAction, createPromiseSaga } from 'store/util';
import { all, takeEvery, put, call } from 'redux-saga/effects';
import { setSessionStorageItem } from 'lib/util';
import {
  checkAccessToken,
  sendResetInquiryPassword,
  updateTempLogin,
  updateResetPassword,
  checkSnsToken,
  fetchFindAccount,
} from 'api/auth';
import reducer from '../common';

export const CHECK_TOKEN = createFetchAction('auth/CHECK_TOKEN');
export const SEND_RESET_INQUIRY_PASSWORD = createFetchAction(
  'auth/SEND_RESET_INQUIRY_PASSWORD',
);
export const UPDATE_RESET_PASSWORD = createFetchAction(
  'auth/UPDATE_RESET_PASSWORD',
);
export const CHECK_SNS_TOKEN = createFetchAction('auth/CHECK_SNS_TOKEN');
export const FIND_ACCOUNT = createFetchAction('auth/FIND_ACCOUNT');

const initialState = {
  checkAccessToken: {},
  resetInquiryPassword: {},
  resetPassword: {},
  checkSnsToken: {},
  findAccount: {},
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    CHECK_TOKEN_INIT: (state, { payload }) => {
      // console.log('CHECK_TOKEN_INIT __ ', payload);
      state.checkAccessToken.success = null;
      state.checkAccessToken.failure = null;
      state.checkAccessToken.data = null;
    },
    CHECK_TOKEN_REQUEST: (state, { payload }) => {
      // console.log('CHECK_TOKEN_REQUEST __ ', payload);
    },
    CHECK_TOKEN_SUCCESS: (state, { payload }) => {
      // console.log('CHECK_TOKEN_SUCCESS __ ', payload);
      state.checkAccessToken.success = true;
      state.checkAccessToken.failure = false;
      state.checkAccessToken.data = payload.data;
    },
    CHECK_TOKEN_FAILURE: (state, { payload }) => {
      // console.log('CHECK_TOKEN_FAILURE __ ', payload);
      state.checkAccessToken.success = false;
      state.checkAccessToken.failure = true;
      state.checkAccessToken.data = payload.data;
      // alert(payload.data.msg);
      // alert(t('EXPIRED_PAGE'));
    },

    SEND_RESET_INQUIRY_PASSWORD_INIT: (state, { payload }) => {
      // console.log('SEND_RESET_INQUIRY_PASSWORD_INIT __ ', payload);
      state.resetInquiryPassword.success = null;
      state.resetInquiryPassword.failure = null;
      state.resetInquiryPassword.data = null;
    },
    SEND_RESET_INQUIRY_PASSWORD_REQUEST: (state, { payload }) => {
      // console.log('SEND_RESET_INQUIRY_PASSWORD_REQUEST __ ', payload);
    },
    SEND_RESET_INQUIRY_PASSWORD_SUCCESS: (state, { payload }) => {
      // console.log('SEND_RESET_INQUIRY_PASSWORD_SUCCESS __ ', payload);
      state.resetInquiryPassword.success = true;
      state.resetInquiryPassword.failure = false;
      state.resetInquiryPassword.data = payload.data;
    },
    SEND_RESET_INQUIRY_PASSWORD_FAILURE: (state, { payload }) => {
      // console.log('SEND_RESET_INQUIRY_PASSWORD_FAILURE __ ', payload);
      state.resetInquiryPassword.success = false;
      state.resetInquiryPassword.failure = true;
      state.resetInquiryPassword.data = payload.data;
    },

    UPDATE_RESET_PASSWORD_INIT: (state, { payload }) => {
      // console.log('RESET_PASSWORD_INIT __ ', payload);
      state.resetPassword.success = null;
      state.resetPassword.failure = null;
      state.resetPassword.data = null;
    },
    UPDATE_RESET_PASSWORD_REQUEST: (state, { payload }) => {
      // console.log('RESET_PASSWORD_REQUEST __ ', payload);
    },
    UPDATE_RESET_PASSWORD_SUCCESS: (state, { payload }) => {
      // console.log('RESET_PASSWORD_SUCCESS __ ', payload);
      state.resetPassword.success = true;
      state.resetPassword.failure = false;
      state.resetPassword.data = payload.data;
    },
    UPDATE_RESET_PASSWORD_FAILURE: (state, { payload }) => {
      state.resetPassword.success = false;
      state.resetPassword.failure = true;
      state.resetPassword.data = payload.data;
    },

    CHECK_SNS_TOKEN_INIT: (state, { payload }) => {
      // console.log('RESET_PASSWORD_INIT __ ', payload);
      state.checkSnsToken.success = null;
      state.checkSnsToken.failure = null;
      state.checkSnsToken.data = null;
    },
    CHECK_SNS_TOKEN_REQUEST: (state, { payload }) => {
      // console.log('RESET_PASSWORD_REQUEST __ ', payload);
    },
    CHECK_SNS_TOKEN_SUCCESS: (state, { payload }) => {
      // console.log('RESET_PASSWORD_SUCCESS __ ', payload);
      state.checkSnsToken.success = true;
      state.checkSnsToken.failure = false;
      state.checkSnsToken.data = payload.data;
    },
    CHECK_SNS_TOKEN_FAILURE: (state, { payload }) => {
      state.checkSnsToken.success = false;
      state.checkSnsToken.failure = true;
      state.checkSnsToken.data = payload.data;
    },
    FIND_ACCOUNT_INIT: (state, { payload }) => {
      // console.log('RESET_PASSWORD_INIT __ ', payload);
      state.findAccount.success = null;
      state.findAccount.failure = null;
      state.findAccount.data = null;
    },
    FIND_ACCOUNT_REQUEST: (state, { payload }) => {
      // console.log('RESET_PASSWORD_REQUEST __ ', payload);
    },
    FIND_ACCOUNT_SUCCESS: (state, { payload }) => {
      // console.log('RESET_PASSWORD_SUCCESS __ ', payload);
      state.findAccount.success = true;
      state.findAccount.failure = false;
      state.findAccount.data = payload.data;
    },
    FIND_ACCOUNT_FAILURE: (state, { payload }) => {
      state.findAccount.success = false;
      state.findAccount.failure = true;
      state.findAccount.data = payload.data;
    },
  },
});

const checkAccessTokenSaga = createPromiseSaga(
  'auth/CHECK_TOKEN',
  checkAccessToken,
);
const resetInquiryPasswordSaga = createPromiseSaga(
  'auth/SEND_RESET_INQUIRY_PASSWORD',
  sendResetInquiryPassword,
);

const updateResetPasswordSaga = createPromiseSaga(
  'auth/UPDATE_RESET_PASSWORD',
  updateResetPassword,
);

const checkSnsTokenSaga = createPromiseSaga(
  'auth/CHECK_SNS_TOKEN',
  checkSnsToken,
);

const findAccountSaga = createPromiseSaga(
  'auth/FIND_ACCOUNT',
  fetchFindAccount,
);

export const actions = slice.actions;

export function* authSaga() {
  yield all([
    takeEvery(CHECK_TOKEN.REQUEST, checkAccessTokenSaga),
    takeEvery(SEND_RESET_INQUIRY_PASSWORD.REQUEST, resetInquiryPasswordSaga),

    takeEvery(UPDATE_RESET_PASSWORD.REQUEST, updateResetPasswordSaga),
    takeEvery(CHECK_SNS_TOKEN.REQUEST, checkSnsTokenSaga),
    takeEvery(FIND_ACCOUNT.REQUEST, findAccountSaga),
  ]);
}

export default slice.reducer;
