import React, { Component, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import windowSize from 'react-window-size';

import NavLogo from 'App/layout/AdminLayout/Navigation/NavLogo';
import NavContent from 'App/layout/AdminLayout/Navigation/NavContent';
import OutsideClick from 'App/layout/AdminLayout/Navigation/OutsideClick';

import * as actionTypes from 'store/actions';
import navigation from 'lib/menu-items';
import { useShallowSelector } from 'lib/util';

function Navigation(props) {
  const { fetchProfile, fetchProfileSuccess } = useShallowSelector(state => ({
    fetchProfile: state.userReducer.fetchProfile.data,
    fetchProfileSuccess: state.userReducer.fetchProfile.success,
  }));
  const [isSnsAccount, setIsSnsAccount] = useState(false);

  useEffect(() => {
    if (fetchProfileSuccess && !!fetchProfile) {
      setIsSnsAccount(fetchProfile.provider !== 'dof');
    }
  }, [fetchProfileSuccess, fetchProfile]);

  const navigationCt = useMemo(() => {
    const snsChildren = navigation.items[0].children.filter(
      item => item.id !== 'passwordChange',
    );

    const snsNavigation = {
      items: [{ ...navigation.items[0], children: snsChildren }],
    };

    console.log('[kkw] snsNavigation ______', snsNavigation);

    return isSnsAccount ? snsNavigation : navigation;
  }, [isSnsAccount]);

  const resize = () => {
    const contentWidth = document.getElementById('root').clientWidth;

    if (this.props.layout === 'horizontal' && contentWidth < 992) {
      this.props.onChangeLayout('vertical');
    }
  };

  const componentDidMount = () => {
    resize();
    window.addEventListener('resize', resize);
  };

  const componentWillUnmount = () => {
    window.removeEventListener('resize', resize);
  };

  let navClass = ['pcoded-navbar'];

  if (
    props.preLayout !== null &&
    props.preLayout !== '' &&
    props.preLayout !== 'layout-6' &&
    props.preLayout !== 'layout-8'
  ) {
    navClass = [...navClass, props.preLayout];
  } else {
    navClass = [
      ...navClass,
      props.layoutType,
      props.navBackColor,
      props.navBrandColor,
      'drp-icon-' + props.navDropdownIcon,
      'menu-item-icon-' + props.navListIcon,
      props.navActiveListColor,
      props.navListTitleColor,
    ];

    if (props.layout === 'horizontal') {
      navClass = [...navClass, 'theme-horizontal'];
    }

    if (props.navBackImage) {
      navClass = [...navClass, props.navBackImage];
    }

    if (props.navIconColor) {
      navClass = [...navClass, 'icon-colored'];
    }

    if (!props.navFixedLayout) {
      navClass = [...navClass, 'menupos-static'];
    }

    if (props.navListTitleHide) {
      navClass = [...navClass, 'caption-hide'];
    }
  }

  if (props.windowWidth < 992 && props.collapseMenu) {
    navClass = [...navClass, 'mob-open'];
  } else if (props.collapseMenu) {
    navClass = [...navClass, 'navbar-collapsed'];
  }

  if (props.preLayout === 'layout-6') {
    document.body.classList.add('layout-6');
    document.body.style.backgroundImage = props.layout6Background;
    document.body.style.backgroundSize = props.layout6BackSize;
  }

  if (props.preLayout === 'layout-8') {
    document.body.classList.add('layout-8');
  }

  if (props.layoutType === 'dark') {
    document.body.classList.add('datta-dark');
  } else {
    document.body.classList.remove('datta-dark');
  }

  if (props.rtlLayout) {
    document.body.classList.add('datta-rtl');
  } else {
    document.body.classList.remove('datta-rtl');
  }

  if (props.boxLayout) {
    document.body.classList.add('container');
    document.body.classList.add('box-layout');
  } else {
    document.body.classList.remove('container');
    document.body.classList.remove('box-layout');
  }

  let navContent = (
    <div className="navbar-wrapper">
      <NavLogo
        collapseMenu={props.collapseMenu}
        windowWidth={props.windowWidth}
        onToggleNavigation={props.onToggleNavigation}
      />
      <NavContent navigation={navigationCt.items} layout={props.layout} />
    </div>
  );
  if (props.windowWidth < 992) {
    navContent = (
      <OutsideClick>
        <div className="navbar-wrapper">
          <NavLogo
            collapseMenu={props.collapseMenu}
            windowWidth={props.windowWidth}
            onToggleNavigation={props.onToggleNavigation}
          />
          <NavContent navigation={navigationCt.items} layout={props.layout} />
        </div>
      </OutsideClick>
    );
  }

  return (
    <>
      <nav className={navClass.join(' ')}>{navContent}</nav>
    </>
  );
}

const mapStateToProps = state => {
  return {
    layout: state.commonReducer.layout,
    preLayout: state.commonReducer.preLayout,
    collapseMenu: state.commonReducer.collapseMenu,
    layoutType: state.commonReducer.layoutType,
    navBackColor: state.commonReducer.navBackColor,
    navBackImage: state.commonReducer.navBackImage,
    navIconColor: state.commonReducer.navIconColor,
    navBrandColor: state.commonReducer.navBrandColor,
    layout6Background: state.commonReducer.layout6Background,
    layout6BackSize: state.commonReducer.layout6BackSize,
    rtlLayout: state.commonReducer.rtlLayout,
    navFixedLayout: state.commonReducer.navFixedLayout,
    boxLayout: state.commonReducer.boxLayout,
    navDropdownIcon: state.commonReducer.navDropdownIcon,
    navListIcon: state.commonReducer.navListIcon,
    navActiveListColor: state.commonReducer.navActiveListColor,
    navListTitleColor: state.commonReducer.navListTitleColor,
    navListTitleHide: state.commonReducer.navListTitleHide,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onToggleNavigation: () => dispatch({ type: actionTypes.COLLAPSE_MENU }),
    onChangeLayout: layout =>
      dispatch({ type: actionTypes.CHANGE_LAYOUT, layout: layout }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Navigation),
);
