import { createAction, createReducer, createSlice } from '@reduxjs/toolkit';

import { useHistory } from 'react-router-dom';
import CODE from 'lib/codeName';
import moment from 'moment';
import { createFetchAction, createPromiseSaga } from 'store/util';
import { all, takeEvery, put, call } from 'redux-saga/effects';
import {
  // setLocalStorageItem,
  // removeLocalStorageItem,
  setSessionStorageItem,
  setCookie,
  getCookie,
  keys,
} from 'lib/util';
import {
  // AuthActions
  UserLoginAction,
} from 'store/reduxActions';

import { serviceLogin } from 'api/service';
import { loginAuthUser } from 'api/user';
import { updateTempLogin } from 'api/auth';
import { pageUrl } from 'lib/mapper';
import { useTranslation, Trans } from 'react-i18next';

export const LOGIN_SERVCIE = createFetchAction('signin/LOGIN_SERVCIE');
export const LOGIN_AUTH_USER = createFetchAction('signin/LOGIN_AUTH_USER');
export const UPDATE_TEMP_LOGIN = createFetchAction('signin/UPDATE_TEMP_LOGIN');

const initialState = {
  loginResponse: null,
  accessToken: '',
  // refreshToken : '',
  // tokenInfo : {},
  userInfo: {},
  userInfoResponse: null,
  loginService: {},
  loginAuth: {},
  loginUrlToken: {},
  tempLogin: {},
  // clientId : '',
};

const slice = createSlice({
  name: 'signin',
  initialState,
  reducers: {
    LOGIN_SERVCIE_INIT: (state, { payload }) => {
      // console.log('SERVICE_LOGIN_REQUEST ', state);
      state.loginService.success = null;
      state.loginService.failure = null;
      state.loginService.data = null;
    },
    LOGIN_SERVCIE_REQUEST: (state, { payload }) => {
      // console.log('SERVICE_LOGIN_REQUEST ', state);
    },
    LOGIN_SERVCIE_SUCCESS: (state, { payload }) => {
      // console.log('USER_LOGIN_SUCCESS', payload);
      state.loginService.success = true;
      state.loginService.failure = false;
      state.loginService.data = payload.data;
    },
    LOGIN_SERVCIE_FAILURE: (state, { payload }) => {
      // console.log('AUTH_LOGIN_FAILURE', payload);
      state.loginService.success = false;
      state.loginService.failure = true;
      state.loginService.data = payload.data;
    },
    // LOGIN AUTH
    LOGIN_AUTH_USER_INIT: (state, { payload }) => {
      // console.log('LOGIN_AUTH_USER_REQUEST ', state);
      state.loginAuth.success = null;
      state.loginAuth.failure = null;
      state.loginAuth.data = null;
    },
    LOGIN_AUTH_USER_REQUEST: (state, { payload }) => {
      // console.log('LOGIN_AUTH_USER_REQUEST ', state);
    },
    LOGIN_AUTH_USER_SUCCESS: (state, { payload }) => {
      // console.log('LOGIN_AUTH_USER_SUCCESS', payload);
      state.loginAuth.success = true;
      state.loginAuth.failure = false;
      state.loginAuth.data = payload.data;
      setSessionStorageItem('_accessToken', payload.headers['x-access-token']);
      state.accessToken = payload.headers['x-access-token'];
    },
    LOGIN_AUTH_USER_FAILURE: (state, { payload }) => {
      // console.log('LOGIN_AUTH_FAILURE', payload);
      state.loginAuth.success = false;
      state.loginAuth.failure = true;
      state.loginAuth.data = payload.data;
    },

    LOGIN_URL_ACCESSTOKEN: (state, { payload }) => {
      // console.log('LOGIN_TO_URL_ACCESSTOKEN ', payload);
      setSessionStorageItem('_accessToken', payload.accessToken);
      state.accessToken = payload.accessToken;
      state.loginUrlToken.success = true;
    },
    LOGIN_URL_ACCESSTOKEN_INIT: (state, { payload }) => {
      // console.log('LOGIN_URL_ACCESSTOKEN_INIT ', payload);
      state.loginUrlToken = {};
    },

    LOGOUT_RESET: (state, { payload }) => {
      // console.log('LOGOUT_RESET');
      state.fetchProfile = {};
      state.accessToken = '';
      // state.userInfoResponse = null;
    },

    UPDATE_TEMP_LOGIN_INIT: (state, { payload }) => {
      // console.log('UPDATE_TEMP_LOGIN_INIT __ ', payload);
      state.tempLogin.success = null;
      state.tempLogin.failure = null;
      state.tempLogin.data = null;
    },
    UPDATE_TEMP_LOGIN_REQUEST: (state, { payload }) => {
      // console.log('UPDATE_TEMP_LOGIN_REQUEST __ ', payload);
    },
    UPDATE_TEMP_LOGIN_SUCCESS: (state, { payload }) => {
      // console.log('UPDATE_TEMP_LOGIN_SUCCESS __ ', payload);
      state.tempLogin.success = true;
      state.tempLogin.failure = false;
      state.tempLogin.data = payload.data;
    },
    UPDATE_TEMP_LOGIN_FAILURE: (state, { payload }) => {
      // console.log('UPDATE_TEMP_LOGIN_FAILURE __ ', payload);
      state.tempLogin.success = false;
      state.tempLogin.failure = true;
      state.tempLogin.data = payload.data;
    },
  },
});

const signinServiceSaga = createPromiseSaga('signin/LOGIN_SERVCIE', serviceLogin);
const signinAuthUserSaga = createPromiseSaga('signin/LOGIN_AUTH_USER', loginAuthUser);
const updateTempLoginSaga = createPromiseSaga('signin/UPDATE_TEMP_LOGIN', updateTempLogin);

export const actions = slice.actions;

export function* signinSaga() {
  yield all([
    takeEvery(LOGIN_SERVCIE.REQUEST, signinServiceSaga),
    takeEvery(LOGIN_AUTH_USER.REQUEST, signinAuthUserSaga),
    takeEvery(UPDATE_TEMP_LOGIN.REQUEST, updateTempLoginSaga),
  ]);
}

export default slice.reducer;
