import { handleActions } from 'redux-actions';
import produce from 'immer';
import { createAction, createReducer, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import CODE from 'lib/codeName';
import { all, takeEvery } from 'redux-saga/effects';
import { createFetchAction, createPromiseSaga } from 'store/util';
import {
  fetchCountryList,
  fetchRegionList,
  fetchLanguageList,
  fetchLegalList,
  uploadProfileImage,
  completeProfileImage,
  deleteProfileImage,
  fetchSurveyList,
  fetchLanguageTranslation,
} from 'api/util';

export const COUNTRY_LIST = createFetchAction('util/COUNTRY_LIST');
export const REGION_LIST = createFetchAction('util/REGION_LIST');
export const LANGUAGE_LIST = createFetchAction('util/LANGUAGE_LIST');
export const LEGAL_LIST = createFetchAction('util/LEGAL_LIST');
export const UPLOAD_PROFILE_IMAGE = createFetchAction(
  'util/UPLOAD_PROFILE_IMAGE',
);
export const UPLOAD_PROFILE_IMAGE_COMPLETE = createFetchAction(
  'util/UPLOAD_PROFILE_IMAGE_COMPLETE',
);
export const DELTE_PROFILE_IMAGE = createFetchAction(
  'util/DELTE_PROFILE_IMAGE',
);
export const SURVEY_LIST = createFetchAction('util/SURVEY_LIST');
export const LANGUAGE_TRANSLATION = createFetchAction(
  'util/LANGUAGE_TRANSLATION',
);

const initialState = {
  languageList: [],
  country: {},
  region: {},
  legal: {},
  uploadProfileImg: {},
  completeProfileImg: {},
  deleteProfileImg: {},
  survey: {},
  translation: {},
};

const slice = createSlice({
  name: 'util',
  initialState,
  reducers: {
    COUNTRY_LIST_REQUEST: (state, { payload }) => {
      // console.log("INIT_COUNTRY_REQUEST", payload);
    },
    COUNTRY_LIST_SUCCESS: (state, { payload }) => {
      console.log('COUNTRY_LIST_SUCCESS', payload);
      state.country.data = payload.data;
      state.country.success = true;
    },
    COUNTRY_LIST_FAILURE: (state, { payload }) => {
      // console.log("REGION_LIST_SUCCESS", payload);
      state.country.success = false;
    },
    REGION_LIST_REQUEST: (state, { payload }) => {
      // console.log("REGION_LIST_REQUEST", payload);
    },
    REGION_LIST_SUCCESS: (state, { payload }) => {
      // console.log("REGION_LIST_SUCCESS", payload);
      state.region.data = payload.data;
      state.region.success = true;
    },
    REGION_LIST_FAILURE: (state, { payload }) => {
      // console.log("REGION_LIST_SUCCESS", payload);
      state.region.success = false;
    },

    LANGUAGE_LIST_REQUEST: (state, { payload }) => {
      console.log('LANGUAGE_LIST_REQUEST', payload);
    },
    LANGUAGE_LIST_SUCCESS: (state, { payload }) => {
      console.log('HDS] LANGUAGE_LIST_SUCCESS', payload);
      state.languageList = payload.data;
      state.languageList.success = true;
    },
    LANGUAGE_LIST_FAILURE: (state, { payload }) => {
      console.log('HDS] LANGUAGE_LIST_FAILURE', payload);
      state.languageList.success = false;
    },
    LEGAL_LIST_REQUEST: (state, { payload }) => {
      console.log('LEGAL_LIST_REQUEST ___', payload);
    },
    LEGAL_LIST_SUCCESS: (state, { payload }) => {
      console.log('INIT_LEGAL_LIST_SUCCESS', payload);
      state.legal.success = true;
      state.legal.list = payload.data.agreementList;
    },
    LEGAL_LIST_FAILURE: (state, { payload }) => {
      state.legal.success = false;
    },

    // uploadProfileImg
    UPLOAD_PROFILE_IMAGE_INIT: (state, { payload }) => {
      console.log('UPLOAD_PROFILE_IMAGE_SUCCESS ___', payload);
      state.uploadProfileImg.success = false;
      state.uploadProfileImg.failure = false;
    },
    UPLOAD_PROFILE_IMAGE_REQUEST: (state, { payload }) => {
      console.log('UPLOAD_PROFILE_IMAGE_REQUEST ___', payload);
    },
    UPLOAD_PROFILE_IMAGE_SUCCESS: (state, { payload }) => {
      console.log('UPLOAD_PROFILE_IMAGE_SUCCESS ___', payload);
      state.uploadProfileImg.success = true;
      state.uploadProfileImg.data = payload.data;
    },
    UPLOAD_PROFILE_IMAGE_FAILURE: (state, { payload }) => {
      state.uploadProfileImg.success = false;
      state.uploadProfileImg.failure = true;
    },

    // completeProfileImg
    UPLOAD_PROFILE_IMAGE_COMPLETE_INIT: (state, { payload }) => {
      console.log('UPLOAD_PROFILE_IMAGE_COMPLETE_REQUEST ___', payload);
      state.completeProfileImg.success = false;
      state.completeProfileImg.failure = false;
    },
    UPLOAD_PROFILE_IMAGE_COMPLETE_REQUEST: (state, { payload }) => {
      console.log('UPLOAD_PROFILE_IMAGE_COMPLETE_REQUEST ___', payload);
    },
    UPLOAD_PROFILE_IMAGE_COMPLETE_SUCCESS: (state, { payload }) => {
      console.log('UPLOAD_PROFILE_IMAGE_COMPLETE_SUCCESS ___', payload);
      state.completeProfileImg.success = true;
      state.completeProfileImg.data = payload.data;
    },
    UPLOAD_PROFILE_IMAGE_COMPLETE_FAILURE: (state, { payload }) => {
      state.completeProfileImg.success = false;
      state.completeProfileImg.failure = true;
    },

    // deleteProfileImg
    DELTE_PROFILE_IMAGE_INIT: (state, { payload }) => {
      console.log('DELTE_PROFILE_IMAGE_REQUEST ___', payload);
      state.deleteProfileImg.success = false;
      state.deleteProfileImg.failure = false;
    },
    DELTE_PROFILE_IMAGE_REQUEST: (state, { payload }) => {
      console.log('DELTE_PROFILE_IMAGE_REQUEST ___', payload);
    },
    DELTE_PROFILE_IMAGE_SUCCESS: (state, { payload }) => {
      console.log('DELTE_PROFILE_IMAGE_SUCCESS ___', payload);
      state.deleteProfileImg.success = true;
      state.deleteProfileImg.data = payload.data;
    },
    DELTE_PROFILE_IMAGE_FAILURE: (state, { payload }) => {
      state.deleteProfileImg.success = false;
      state.deleteProfileImg.failure = true;
    },

    SURVEY_LIST_REQUEST: (state, { payload }) => {
      // console.log("INIT_COUNTRY_REQUEST", payload);
    },
    SURVEY_LIST_SUCCESS: (state, { payload }) => {
      console.log('SURVEY_LIST_SUCCESS', payload);
      state.survey.data = payload.data;
      state.survey.success = true;
    },
    SURVEY_LIST_FAILURE: (state, { payload }) => {
      // console.log("REGION_LIST_SUCCESS", payload);
      state.survey.success = false;
    },

    LANGUAGE_TRANSLATION_REQUEST: (state, { payload }) => {
      // console.log("INIT_COUNTRY_REQUEST", payload);
    },
    LANGUAGE_TRANSLATION_SUCCESS: (state, { payload }) => {
      console.log('LANGUAGE_TRANSLATION', payload);
      state.translation.data = payload.data;
      state.translation.success = true;
    },
    LANGUAGE_TRANSLATION_FAILURE: (state, { payload }) => {
      // console.log("REGION_LIST_SUCCESS", payload);
      state.translation.success = false;
    },
  },
});

const countryListSaga = createPromiseSaga(
  'util/COUNTRY_LIST',
  fetchCountryList,
);
const regionListSaga = createPromiseSaga('util/REGION_LIST', fetchRegionList);
const languageListSaga = createPromiseSaga(
  'util/LANGUAGE_LIST',
  fetchLanguageList,
);
const legalListSaga = createPromiseSaga('util/LEGAL_LIST', fetchLegalList);
const uploadProfileImageSaga = createPromiseSaga(
  'util/UPLOAD_PROFILE_IMAGE',
  uploadProfileImage,
);
const completeProfileImageSaga = createPromiseSaga(
  'util/UPLOAD_PROFILE_IMAGE_COMPLETE',
  completeProfileImage,
);
const deleteProfileImageSaga = createPromiseSaga(
  'util/DELTE_PROFILE_IMAGE',
  deleteProfileImage,
);
const surveyListSaga = createPromiseSaga('util/SURVEY_LIST', fetchSurveyList);
const languageTranslationSaga = createPromiseSaga(
  'util/LANGUAGE_TRANSLATION',
  fetchLanguageTranslation,
);

export const actions = slice.actions;

export function* utilSaga() {
  yield all([
    takeEvery(COUNTRY_LIST.REQUEST, countryListSaga),
    takeEvery(REGION_LIST.REQUEST, regionListSaga),
    takeEvery(LANGUAGE_LIST.REQUEST, languageListSaga),
    takeEvery(LEGAL_LIST.REQUEST, legalListSaga),
    takeEvery(UPLOAD_PROFILE_IMAGE.REQUEST, uploadProfileImageSaga),
    takeEvery(UPLOAD_PROFILE_IMAGE_COMPLETE.REQUEST, completeProfileImageSaga),
    takeEvery(DELTE_PROFILE_IMAGE.REQUEST, deleteProfileImageSaga),
    takeEvery(SURVEY_LIST.REQUEST, surveyListSaga),
    takeEvery(LANGUAGE_TRANSLATION.REQUEST, languageTranslationSaga),
  ]);
}

export default slice.reducer;
