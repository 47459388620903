export default {
  EMAIL_CHECK: '/user/email/check',
  CODE_CHECK: '/user/code/check',

  LANGUAGE_LIST: '/util/support/language',
  SIGN_UP: '/user/signup',
  LOG_IN: '/user/login',
  SCOPE_REGIST: '/user/scope/regist',
  REQUIRE_SCOPE_LIST: '/user/require/scope',
  OAUTH_CLIENT_DELETE: '/user/oauth/client',
  PW_RESET_EMAIL_CHECK: '/user/password/reset/email/check',
  TEMP_LOG_IN: '/user/temp/login',
  PW_RESET_DELETE: '/user/password/reset',
  PW_RESET: '/user/password/reset',
  SERVICE_CHECK: '/user/service/check',

  AUTH_LOGIN: '/auth/login',
  AUTH_USERINFO: '/auth/user',
  AUTH_USERINFO_UPDATE: '/auth/user',
  AUTH_USER_PASSWORD_UPDATE: '/auth/user/password',
  AUTH_USER_LINKED_SERVICE: '/auth/user/linked/service',
  AUTH_SIGN_OUT: '/auth/user/signout',
  SERVICE_USER_INFO: '/user',
  SIGN_UP_USER_INFO: '/user',
  SERVICE_PASSWORD_CHANGE: '/user/password/change',
  NICKNAME_CHECK: '/user/nickname/check',

  // v2
  // auth
  CHECK_TOKEN: '/auth/token',
  CHECK_SERVICE: '/auth/service',
  CHECK_DOF_ID: '/auth/dofid',
  CHECK_EMAIL_VALID: '/auth/email/signup',
  CHECK_EMAIL_CODE: '/auth/email/code',
  RESET_INQUIRY_PASSWORD: '/auth/email/passwordreset',
  RESET_PASSWORD: '/auth/password/reset',
  LOGIN_AUTH_TEMP: '/auth/temp/login',
  SIGN_UP_AUTH: '/auth/signup',
  CHECK_SNS_TOKEN: '/auth/sns/check',
  FIND_ACCOUNT_EMAIL: '/auth/email/find',

  // service
  LOGIN_SERVCIE: '/service/login',
  SIGN_UP_SERVICE: '/service/signup',

  // user
  LOGIN_AUTH_USER: '/user/login',
  USER_PROFILE: '/user/profile',
  CHANGE_USER_PASSWORD: '/user/password',
  LINKED_SERVICE: '/user/linked/service',
  SIGN_OUT: '/user/signout',
  USER_WITHDRAWAL: '/user/withdrawal',
  USER_SURVEY: '/user/survey/withdrawal',
  WITHDRAWAL_POINT: '/user/withdrawal/point',
  WITHDRAWAL_PROJECT: '/user/withdrawal/project/unfinish',

  // util
  COUNTRY_LIST: '/util/country/list',
  LEGAL_LIST: '/util/legal/agreement/list',
  REGION_LIST: '/util/country/region/list',
  SURVEY_LIST: '/util/survey/withdrawal',
  LANGUAGE_TRANSLATION: '/util/translation',

  // file
  UPLOAD_PROFILE_IMAGE: '/file/upload/profileimg',
  UPLOAD_PROFILE_IMAGE_COMPLETE: '/file/upload/profileimg/complete',
  DELTE_PROFILE_IMAGE: '/file/profileimg',
};
