import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { HashRouter, BrowserRouter } from 'react-router-dom';

import App from 'App/index';
import * as serviceWorker from 'serviceWorker';
// import reducer from 'store/reducer';
import reducer from 'store/modules';
import config from 'lib/config';
import { store, persistor } from './store';
import Loader from 'App/layout/Loader';
// import store from 'store';
//

// if (process.env.NODE_ENV === 'production') console.log = () => {};

// const store = createStore(reducer);
const app = (
  <Provider store={store}>
    {/* <HashRouter basename={config.basename}>
            <App />
        </HashRouter> */}

    <BrowserRouter basename={config.basename}>
      <App />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
