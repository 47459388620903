import React, { Component } from 'react';
import { connect } from 'react-redux';
import windowSize from 'react-window-size';

import * as actionTypes from 'store/actions';

function OutsideClick(props) {
  this.setWrapperRef = this.setWrapperRef.bind(this);
  this.handleOutsideClick = this.handleOutsideClick.bind(this);

  const componentDidMount = () => {
    document.addEventListener('mousedown', this.handleOutsideClick);
  };

  const componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleOutsideClick);
  };

  const setWrapperRef = node => {
    this.wrapperRef = node;
  };

  /**
   * close menu if clicked on outside of element
   */
  const handleOutsideClick = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (this.props.windowWidth < 992 && this.props.collapseMenu) {
        this.props.onToggleNavigation();
      }
    }
  };

  return (
    <div className="nav-outside" ref={setWrapperRef}>
      {props.children}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    collapseMenu: state.collapseMenu,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onToggleNavigation: () => dispatch({ type: actionTypes.COLLAPSE_MENU }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OutsideClick);
