import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Dropdown} from 'react-bootstrap';
import windowSize from 'react-window-size';

import NavSearch from 'App/layout/AdminLayout/NavBar/NavLeft/NavSearch';

import components from "store/constant";
import * as actionTypes from "store/actions";

function NavLeft(props) {

    let iconFullScreen = ['feather'];
    iconFullScreen = (props.isFullScreen) ? [...iconFullScreen, 'icon-minimize'] : [...iconFullScreen, 'icon-maximize'];

    let navItemClass = ['nav-item'];
    if (props.windowWidth <= 575) {
        navItemClass = [...navItemClass, 'd-none'];
    }
    let dropdownRightAlign = false;
    if (props.rtlLayout) {
        dropdownRightAlign = true;
    }


    return (
        <>
            <ul className="navbar-nav mr-auto">
                <li><a href={components.BLANK_LINK} className="full-screen" onClick={props.onFullScreen}><i className={iconFullScreen.join(' ')} /></a></li>
                <li className={navItemClass.join(' ')}>
                    <Dropdown alignRight={dropdownRightAlign}>
                        <Dropdown.Toggle variant={'link'} id="dropdown-basic">
                            Dropdown
                        </Dropdown.Toggle>
                        <ul>
                            <Dropdown.Menu>
                                <li><a className="dropdown-item" href={components.BLANK_LINK}>Action</a></li>
                                <li><a className="dropdown-item" href={components.BLANK_LINK}>Another action</a></li>
                                <li><a className="dropdown-item" href={components.BLANK_LINK}>Something else here</a></li>
                            </Dropdown.Menu>
                        </ul>
                    </Dropdown>
                </li>
                <li className="nav-item"><NavSearch/></li>
            </ul>
        </>
    );
}

const mapStateToProps = state => {
    return {
        isFullScreen: state.isFullScreen,
        rtlLayout: state.rtlLayout
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onFullScreen: () => dispatch({type: actionTypes.FULL_SCREEN}),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NavLeft);
