import React, { Component } from 'react';
import { connect } from 'react-redux';

import NavLeft from 'App/layout/AdminLayout/NavBar/NavLeft';
import NavRight from 'App/layout/AdminLayout/NavBar/NavRight';

import components from 'store/constant';
import * as actionTypes from 'store/actions';
import { useTranslation } from 'react-i18next';
import T from 'components/Common/text/T';
import { icon_dof } from 'components/base/images';

function NavBar(props) {
  let headerClass = ['navbar', 'pcoded-header', 'navbar-expand-lg', props.headerBackColor];
  if (props.headerFixedLayout) {
    headerClass = [...headerClass, 'headerpos-fixed'];
  }

  let toggleClass = ['mobile-menu'];
  if (props.collapseMenu) {
    toggleClass = [...toggleClass, 'on'];
  }

  return (
    <>
      <header className={headerClass.join(' ')}>
        <div className="m-header">
          <a
            className={toggleClass.join(' ')}
            id="mobile-collapse1"
            href={components.BLANK_LINK}
            onClick={props.onToggleNavigation}
          >
            <span />
          </a>
          <a href={components.BLANK_LINK} className="b-brand">
            <div className="b-bg">
              {/* <i className="feather icon-trending-up" /> */}
              <img src={icon_dof} />
            </div>
            <span className="b-title">
              <T>SERVICE_NAME</T>
            </span>
          </a>
        </div>
        <a className="mobile-menu" id="mobile-header" href={components.BLANK_LINK}>
          <i className="feather icon-more-horizontal" />
        </a>
        <div className="collapse navbar-collapse">
          <NavRight rtlLayout={props.rtlLayout} />
        </div>
      </header>
    </>
  );
}

const mapStateToProps = state => {
  return {
    rtlLayout: state.rtlLayout,
    headerBackColor: state.headerBackColor,
    headerFixedLayout: state.headerFixedLayout,
    collapseMenu: state.collapseMenu,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onToggleNavigation: () => dispatch({ type: actionTypes.COLLAPSE_MENU }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
