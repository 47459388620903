import React from 'react';
const SignUp = React.lazy(() => import('containers/signup/SignupContainer'));
const SignupSuccess = React.lazy(() =>
  import('containers/signup/SignupSuccess'),
);
const Login = React.lazy(() => import('containers/login/LoginContainer'));
const AccountInfo = React.lazy(() => import('containers/login/AccountInfo'));
const PasswordReset = React.lazy(() =>
  import('containers/password/PasswordResetContainer'),
);
const PassowrdResetInquiry = React.lazy(() =>
  import('containers/password/PasswordResetInquiryContainer'),
);
const LegalContentComponent = React.lazy(() =>
  import('components/Legal/LegalContentComponent'),
);

const FindTypeList = React.lazy(() =>
  import('containers/find/FindTypeListContainer'),
);
const FindAccount = React.lazy(() =>
  import('containers/find/FindAccountContainer'),
);
const FindAccountResult = React.lazy(() =>
  import('containers/find/FindAccountResultContainer'),
);

const route = [
  { path: '/signup', exact: true, name: 'Signup', component: SignUp },
  {
    path: '/signup/success',
    exact: true,
    name: 'SignupSuccess',
    component: SignupSuccess,
  },
  {
    path: '/signup/:serviceName',
    exact: true,
    name: 'Signup',
    component: SignUp,
  },
  { path: '/login', exact: true, name: 'Signin', component: Login },
  {
    path: '/login/info',
    exact: true,
    name: 'AccountInfo',
    component: AccountInfo,
  },
  {
    path: '/login/:serviceName',
    exact: true,
    name: 'Signin',
    component: Login,
  },
  {
    path: '/password/reset',
    exact: true,
    name: 'PasswordReset',
    component: PasswordReset,
  },
  {
    path: '/password/inquiry',

    exact: true,
    name: 'PasswordResetInquiry',
    component: PassowrdResetInquiry,
    // component: PasswordReset,
  },
  {
    path: '/password/inquiry/:serviceName',
    exact: true,
    name: 'PasswordResetInquiry',
    component: PassowrdResetInquiry,
  },
  {
    path: '/legal/privacy',
    exact: true,
    name: 'LegalContentComponent',
    component: LegalContentComponent,
  },
  {
    path: '/legal/marketing',
    exact: true,
    name: 'LegalContentComponent',
    component: LegalContentComponent,
  },
  {
    path: '/find/account',
    exact: true,
    name: 'FindTypeList',
    component: FindTypeList,
  },
  {
    path: '/find/account/:fType',
    exact: true,
    name: 'FindAccount',
    component: FindAccount,
  },
  {
    path: '/find/account/:fType/result',
    exact: true,
    name: 'FindAccountResult',
    component: FindAccountResult,
  },
];

export default route;
