import { handleActions } from 'redux-actions'; 
import produce from 'immer';
import {  createAction, createReducer } from '@reduxjs/toolkit';
import moment from 'moment';
import CODE from 'lib/codeName';

export const INIT_MODAL = createAction('modal/INIT_MODAL');
export const ON_OFF_MODAL = createAction('modal/ON_OFF_MODAL');
export const TWO_OPTION_CLOSE = createAction('modal/TWO_OPTION_CLOSE');

const initialState = {
    TwoOption : {
        title : '',
        content : '',
        show : false,
        option1 : '',
        option2: ''
    },
    Notify : {
        title : '',
        content :'',
        show : false
    }
};

const ModalReducer = createReducer(initialState, {
    [INIT_MODAL]: (state, action) => {
        console.log("INIT_MODAL ", action.payload);
        switch(action.payload.type){
            case 'twoOption':
                state.TwoOption.title = action.payload.modalData.title;
                state.TwoOption.content = action.payload.modalData.content;
                state.TwoOption.option1 = action.payload.modalData.option1;
                state.TwoOption.option2 = action.payload.modalData.option2;
                state.TwoOption.show = true;
            break;
            case 'notify':
                state.Notify.title = action.payload.title;
                state.Notify.content = action.payload.content;
                state.Notify.show = true;

                console.log("NOTIFY MODAL ", action.payload);
            break;
        }
        return state;
    },
    [ON_OFF_MODAL] : (state, action)=>{
        console.log('ModalReducer ', action.payload);
        switch(action.payload.type){
            case 'twoOption':
                state.TwoOption.show = action.payload.show;

                break;
                case 'notify':
                    console.log('Notify 여기 왔나? ',action.payload.show);
                    state.Notify.show = action.payload.show;
            break;
        }
        return state;
    },
    [TWO_OPTION_CLOSE] : (state, action)=>{
        state.TwoOption.show = false;
        return state;
    }
});

export default ModalReducer;


