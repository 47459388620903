import { createAction, createReducer, createSlice } from '@reduxjs/toolkit';
import CODE from 'lib/codeName';
import { createFetchAction, createPromiseSaga } from 'store/util';
import { all, takeEvery, put, call } from 'redux-saga/effects';
import {
  fetchUserProfile,
  updateUserProfile,
  updateUserPassword,
  deleteUserProfile,
  withdrawalUserSurvey,
  fetchWithdrawalPoint,
  fetchWithdrawalProject,
} from 'api/user';
import { useTranslation } from 'react-i18next';

export const FETCH_USER_PROFILE = createFetchAction('user/FETCH_USER_PROFILE');
export const UPDATE_USER_PROFILE = createFetchAction(
  'user/UPDATE_USER_PROFILE',
);
export const UPDATE_USER_PASSWORD = createFetchAction(
  'user/UPDATE_USER_PASSWORD',
);
export const DELETE_USER_PROFILE = createFetchAction(
  'user/DELETE_USER_PROFILE',
);
export const WITHDRAWAL_USER_SURVAY = createFetchAction(
  'user/WITHDRAWAL_USER_SURVAY',
);
export const FETCH_WITHDRAWAL_POINT = createFetchAction(
  'user/FETCH_WITHDRAWAL_POINT',
);
export const FETCH_WITHDRAWAL_PROJECT = createFetchAction(
  'user/FETCH_WITHDRAWAL_PROJECT',
);

const initialState = {
  fetchProfile: {},
  updateProfile: {},
  updatePassword: {},
  deleteProfile: {},
  userSurvey: {},
  fetchPoint: {},
  fetchProject: {},
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    FETCH_USER_PROFILE_INIT: (state, { payload }) => {
      // console.log('FETCH_USER_PROFILE_REQUEST', payload);
    },
    FETCH_USER_PROFILE_REQUEST: (state, { payload }) => {
      // console.log('FETCH_USER_PROFILE_REQUEST', payload);
    },
    FETCH_USER_PROFILE_SUCCESS: (state, { payload }) => {
      // console.log('FETCH_USER_PROFILE_SUCCESS', payload);
      state.fetchProfile.data = payload.data.userInfo;
      state.fetchProfile.success = true;
      state.fetchProfile.failure = false;
      // 스코프 승인 필요
    },
    FETCH_USER_PROFILE_FAILURE: (state, { payload }) => {
      // console.log('FETCH_USER_PROFILE_FAILURE', payload);
      state.fetchProfile.failure = true;
      state.fetchProfile.success = false;
    },
    UPDATE_USER_PROFILE_INIT: (state, { payload }) => {
      // console.log('UPDATE_USER_PROFILE_INIT', payload);
      state.updateProfile.failure = false;
      state.updateProfile.success = false;
    },
    UPDATE_USER_PROFILE_REQUEST: (state, { payload }) => {
      // console.log('UPDATE_USER_PROFILE_REQUEST', payload);
    },
    UPDATE_USER_PROFILE_SUCCESS: (state, { payload }) => {
      // console.log('UPDATE_USER_PROFILE_SUCCESS', payload);

      state.updateProfile.success = true;
      // 스코프 승인 필요
    },
    UPDATE_USER_PROFILE_FAILURE: (state, { payload }) => {
      // console.log('UPDATE_USER_PROFILE_FAILURE', payload);
      state.updateProfile.failure = true;
      state.updateProfile.success = false;
    },

    UPDATE_USER_PASSWORD_INIT: (state, { payload }) => {
      // console.log('UPDATE_USER_PASSWORD_INIT', payload);
      state.updatePassword.failure = false;
      state.updatePassword.success = false;
    },
    UPDATE_USER_PASSWORD_REQUEST: (state, { payload }) => {
      // console.log('UPDATE_USER_PROFILE_REQUEST', payload);
    },
    UPDATE_USER_PASSWORD_SUCCESS: (state, { payload }) => {
      // console.log('UPDATE_USER_PROFILE_SUCCESS', payload);
      state.updatePassword.success = true;
      state.updatePassword.failure = false;
    },
    UPDATE_USER_PASSWORD_FAILURE: (state, { payload }) => {
      // console.log('UPDATE_USER_PROFILE_FAILURE', payload);
      state.updatePassword.failure = true;
      state.updatePassword.success = false;
    },

    DELETE_USER_PROFILE_INIT: (state, { payload }) => {
      // console.log('FETCH_USER_PROFILE_REQUEST', payload);
      state.deleteProfile.failure = false;
      state.deleteProfile.success = false;
    },
    DELETE_USER_PROFILE_REQUEST: (state, { payload }) => {
      // console.log('DELETE_USER_PROFILE_REQUEST', payload);
    },
    DELETE_USER_PROFILE_SUCCESS: (state, { payload }) => {
      // console.log('FETCH_USER_PROFILE_SUCCESS', payload);
      state.deleteProfile.success = true;
      state.deleteProfile.failure = false;
      // 스코프 승인 필요
    },
    DELETE_USER_PROFILE_FAILURE: (state, { payload }) => {
      // console.log('FETCH_USER_PROFILE_FAILURE', payload);
      state.deleteProfile.failure = true;
      state.deleteProfile.success = false;
    },

    WITHDRAWAL_USER_SURVAY_INIT: (state, { payload }) => {
      // console.log('FETCH_USER_PROFILE_REQUEST', payload);
      state.userSurvey.failure = false;
      state.userSurvey.success = false;
    },
    WITHDRAWAL_USER_SURVAY_REQUEST: (state, { payload }) => {
      // console.log('DELETE_USER_PROFILE_REQUEST', payload);
    },
    WITHDRAWAL_USER_SURVAY_SUCCESS: (state, { payload }) => {
      // console.log('FETCH_USER_PROFILE_SUCCESS', payload);
      state.userSurvey.success = true;
      state.userSurvey.failure = false;
      // 스코프 승인 필요
    },
    WITHDRAWAL_USER_SURVAY_FAILURE: (state, { payload }) => {
      // console.log('FETCH_USER_PROFILE_FAILURE', payload);
      state.userSurvey.failure = true;
      state.userSurvey.success = false;
    },
    // point
    FETCH_WITHDRAWAL_POINT_INIT: (state, { payload }) => {
      // console.log('FETCH_USER_POINT_INIT', payload);
    },
    FETCH_WITHDRAWAL_POINT_REQUEST: (state, { payload }) => {
      // console.log('FETCH_USER_POINT_REQUEST', payload);
    },
    FETCH_WITHDRAWAL_POINT_SUCCESS: (state, { payload }) => {
      // console.log('FETCH_USER_POINT_SUCCESS', payload);
      state.fetchPoint.data = payload.data.list;
      state.fetchPoint.success = true;
      state.fetchPoint.failure = false;
      // 스코프 승인 필요
    },
    FETCH_WITHDRAWAL_POINT_FAILURE: (state, { payload }) => {
      // console.log('FETCH_USER_POINT_FAILURE', payload);
      state.fetchPoint.failure = true;
      state.fetchPoint.success = false;
    },
    // project
    FETCH_WITHDRAWAL_PROJECT_INIT: (state, { payload }) => {
      // console.log('FETCH_USER_PROJECT_INIT', payload);
    },
    FETCH_WITHDRAWAL_PROJECT_REQUEST: (state, { payload }) => {
      // console.log('FETCH_USER_PROJECT_REQUEST', payload);
    },
    FETCH_WITHDRAWAL_PROJECT_SUCCESS: (state, { payload }) => {
      // console.log('FETCH_USER_PROJECT_SUCCESS', payload);
      state.fetchProject.data = payload.data.list;
      state.fetchProject.canWithdrawal = payload.data.canWithdrawal;
      state.fetchProject.success = true;
      state.fetchProject.failure = false;
      // 스코프 승인 필요
    },
    FETCH_USER_PROJECT_FAILURE: (state, { payload }) => {
      // console.log('FETCH_USER_PROJECT_FAILURE', payload);
      state.fetchProject.failure = true;
      state.fetchProject.success = false;
    },
  },
});

const fetchUserProfileSaga = createPromiseSaga(
  'user/FETCH_USER_PROFILE',
  fetchUserProfile,
);
const upadateUserProfileSaga = createPromiseSaga(
  'user/UPDATE_USER_PROFILE',
  updateUserProfile,
);
const upadateUserPasswordSaga = createPromiseSaga(
  'user/UPDATE_USER_PASSWORD',
  updateUserPassword,
);
const deleteUserProfileSaga = createPromiseSaga(
  'user/DELETE_USER_PROFILE',
  deleteUserProfile,
);
const withdrawalUserSurveySaga = createPromiseSaga(
  'user/WITHDRAWAL_USER_SURVAY',
  withdrawalUserSurvey,
);
const fetchWithdrwalPointSaga = createPromiseSaga(
  'user/FETCH_WITHDRAWAL_POINT',
  fetchWithdrawalPoint,
);
const fetchWithdrwalProjectSaga = createPromiseSaga(
  'user/FETCH_WITHDRAWAL_PROJECT',
  fetchWithdrawalProject,
);

export const actions = slice.actions;

export function* userSaga() {
  yield all([
    takeEvery(FETCH_USER_PROFILE.REQUEST, fetchUserProfileSaga),
    takeEvery(UPDATE_USER_PROFILE.REQUEST, upadateUserProfileSaga),
    takeEvery(UPDATE_USER_PASSWORD.REQUEST, upadateUserPasswordSaga),
    takeEvery(DELETE_USER_PROFILE.REQUEST, deleteUserProfileSaga),
    takeEvery(WITHDRAWAL_USER_SURVAY.REQUEST, withdrawalUserSurveySaga),
    takeEvery(FETCH_WITHDRAWAL_POINT.REQUEST, fetchWithdrwalPointSaga),
    takeEvery(FETCH_WITHDRAWAL_PROJECT.REQUEST, fetchWithdrwalProjectSaga),
  ]);
}

export default slice.reducer;
