import { axs } from 'api/config/axiosConfig';
import apiUrl from 'lib/url';

export function fetchAuthLogin(payload) {
  console.log('fetchAuthLogin', payload);
  return axs({ url: apiUrl.AUTH_LOGIN, method: 'post', data: payload });
}

export function fetchAuthUserInfo(payload) {
  console.log('fetchAuthUserInfo', payload);
  return axs({ url: apiUrl.AUTH_USERINFO, method: 'get', params: payload });
}

export function fetchAuthUserInfoUpdate(payload) {
  console.log('fetchAuthUserInfoUpdate', payload);
  return axs({
    url: apiUrl.AUTH_USERINFO_UPDATE,
    method: 'put',
    data: payload,
  });
}

export function fetchAuthUserPasswordUpdate(payload) {
  console.log('fetchAuthUserPasswordUpdate', payload);
  return axs({
    url: apiUrl.AUTH_USER_PASSWORD_UPDATE,
    method: 'put',
    data: payload,
  });
}

export function fetchAuthUserLinkedService(payload) {
  console.log('fetchAuthUserLinkedService', payload);
  return axs({
    url: apiUrl.AUTH_USER_LINKED_SERVICE,
    method: 'get',
    params: payload,
  });
}

export function fetchAuthSignout(payload) {
  console.log('fetchAuthSignout', payload);
  return axs({ url: apiUrl.AUTH_SIGN_OUT, method: 'put', data: payload });
}

// V2
export function sendResetInquiryPassword(payload) {
  console.log('[API_V2]_[ sendResetInquiryPassword ] : ', payload);
  return axs({
    url: apiUrl.RESET_INQUIRY_PASSWORD,
    method: 'post',
    data: payload,
  });
}

export function updateTempLogin(payload) {
  console.log('[API_V2]_[ updateTempLogin ] : ', payload);
  return axs({ url: apiUrl.LOGIN_AUTH_TEMP, method: 'post', data: payload });
}

export function updateResetPassword(payload) {
  console.log('[API_V2]_[ updateResetPassword ] : ', payload);
  return axs({ url: apiUrl.RESET_PASSWORD, method: 'put', data: payload });
}

export function checkAccessToken(payload) {
  console.log('[API_V2]_[ checkAccessToken ] : ', payload);
  return axs({ url: apiUrl.CHECK_TOKEN, method: 'post', data: payload });
}

export function checkDuplicateDofid(payload) {
  console.log('[API_V2]_[ checkDuplicateDofid ] : ', payload);
  return axs({ url: apiUrl.CHECK_DOF_ID, method: 'post', data: payload });
}

export function submitAuthSignup(payload) {
  console.log('[API_V2]_[ submitAuthSignup ] : ', payload);
  return axs({ url: apiUrl.SIGN_UP_AUTH, method: 'post', data: payload });
}

export function checkEmailValid(payload) {
  console.log('[API_V2]_[ checkEmailValid ] : ', payload);
  return axs({ url: apiUrl.CHECK_EMAIL_VALID, method: 'post', data: payload });
}

export function checkEmailCode(payload) {
  console.log('[API_V2]_[ checkEmailCode ] : ', payload);
  return axs({ url: apiUrl.CHECK_EMAIL_CODE, method: 'put', data: payload });
}

export function checkSnsToken(payload) {
  console.log('[API_V2]_[ checkSnsToken ] : ', payload);
  return axs({ url: apiUrl.CHECK_SNS_TOKEN, method: 'post', data: payload });
}

export function convertAuthCodeToAccessToken(payload) {
  console.log('[API_V2]_[ convertAuthCodeToAccessToken ] : ', payload);
  return axs({ url: `oauth2.googleapis.com`, method: 'post', data: payload });
}

export function fetchFindAccount(payload) {
  return axs({
    url: apiUrl.FIND_ACCOUNT_EMAIL,
    method: 'get',
    params: payload,
  });
}
