export const BREAK_POINT_MOBILE = `320px`;
export const BREAK_POINT_MOBILE_MAX = `767px`;
export const BREAK_POINT_TABLET = `768px`;
export const BREAK_POINT_TABLET_EXCLUDE_SCROLL_BAR = `753px`;
export const BREAK_POINT_TABLET_MAX = `1023px`;
export const BREAK_POINT_PC = `1024px`;
export const BREAK_POINT_PC_EXCLUDE_SCROLL_BAR = `1009px`;

export const requiredCountryListForLab = [116, 109];
export const companyType = {
  clinic: {
    id: 1,
    label: 'Clinic',
    index: 'clinic',
  },
  lab: {
    id: 2,
    label: 'CAD Lab',
    index: 'lab',
  },
  milling: {
    id: 3,
    label: 'Milling Center',
    index: 'milling',
  },
};
// export const companyType = {
//   clinic: {
//     id: 1,
//     label: "Clinic",
//     index: "clinic",
//   },
//   lab: {
//     id: 2,
//     label: "CAD Lab",
//     index: "lab",
//   },
//   milling: {
//     id: 3,
//     label: "Milling Center",
//     index: "milling",
//   },
//   designer: {
//     id: 4,
//     label: "Designer",
//     index: "designer",
//   },
// };

export const legalType = [
  {
    type: 'privacy',
    text: 'Privacy Policy',
  },
  {
    type: 'terms',
    text: 'Terms of Service',
  },
  {
    type: 'marketing',
    text: 'Marketing',
  },
];

export const visibilityType = {
  public: {
    id: 0,
    label: 'Public',
    index: 'public',
  },
  partnerShip: {
    id: 1,
    label: 'Public to Partners',
    index: 'partnerShip',
  },
};

export const pageUrl = {
  index: '/',
  home: '/home',
  error: {
    index: '/error',
    server: '/error/500',
    notFound: '/error/404',
  },
  singup: '/signup',
  login: '/login',
  loginInfo: '/login/info',
  passwordReset: '/password/reset',
  passwordInquiry: '/password/inquiry',
  passwordChange: '/auth/password/change',
  findAccount: {
    index: '/find/account',
    personal: '/find/account/personal',
    company: '/find/account/company',
    result: '/find/account/:fType/result',
  },
  mypage: '/auth/mypage',
};

export const mappers = {
  project: {
    timeline: [
      {
        name: 'create',
        id: 0,
        title: 'Created',
        color: '#D20000',
      },
      {
        name: 'upload',
        id: 1,
        title: 'Uploaded',
        color: '#FEB700',
      },
      {
        name: 'download',
        id: 2,
        title: 'Downloaded',
        color: '#00884F',
      },
      {
        name: 'completed',
        id: 3,
        title: 'Completed',
        color: '#75259A',
      },
    ],
  },
  sync_project: {
    timeline: [
      {
        name: 'created',
        id: 0,
        title: 'Created',
        color: '#D20000',
      },
      {
        name: 'uploaded',
        id: 1,
        title: 'Uploaded',
        color: '#FEB700',
      },
      {
        name: 'downloaded',
        id: 2,
        title: 'Downloaded',
        color: '#00884F',
      },
      {
        name: 'completed',
        id: 3,
        title: 'Completed',
        color: '#75259A',
      },
    ],
    timeline_md: 3,
  },
  bridge_project: {
    timeline: [
      {
        name: 'created',
        id: 0,
        title: 'Created',
        color: '#ffc400',
      },
      {
        name: 'matching',
        id: 1,
        title: 'Matching',
        color: '#0fdc0f',
      },
      {
        name: 'working',
        id: 2,
        title: 'Working',
        color: '#05abe9',
      },
      {
        name: 'done',
        id: 3,
        title: 'Done',
        color: '#000000',
      },
      {
        name: 'completed',
        id: 4,
        title: 'Completed',
        color: '#9a00e5',
      },
    ],
    timeline_md: 2,
  },
  teeth: {
    fdi: {
      18: 0,
      17: 1,
      16: 2,
      15: 3,
      14: 4,
      13: 5,
      12: 6,
      11: 7,
      21: 8,
      22: 9,
      23: 10,
      24: 11,
      25: 12,
      26: 13,
      27: 14,
      28: 15,
      38: 16,
      37: 17,
      36: 18,
      35: 19,
      34: 20,
      33: 21,
      32: 22,
      31: 23,
      41: 24,
      42: 25,
      43: 26,
      44: 27,
      45: 28,
      46: 29,
      47: 30,
      48: 31,
    },
    fdi_teeth_num: [
      18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
      36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
    ],
    universal: {
      1: 0,
      2: 1,
      3: 2,
      4: 3,
      5: 4,
      6: 5,
      7: 6,
      8: 7,
      9: 8,
      10: 9,
      11: 10,
      12: 11,
      13: 12,
      14: 13,
      15: 14,
      16: 15,
      17: 16,
      18: 17,
      19: 18,
      20: 19,
      21: 20,
      22: 21,
      23: 22,
      24: 23,
      25: 24,
      26: 25,
      27: 26,
      28: 27,
      29: 28,
      30: 29,
      31: 30,
      32: 31,
    },
    universal_teeth_num: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32,
    ],
  },
};

export const languageList = [
  {
    id: 0,
    code: 'ko',
    text: '한국어',
  },
  {
    id: 1,
    code: 'en',
    text: 'English',
  },
  {
    id: 2,
    code: 'ja',
    text: '日本語',
  },
];
