import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router-dom";
import windowSize from "react-window-size";

import NavIcon from "App/layout/AdminLayout/Navigation/NavContent/NavIcon";
import NavBadge from "App/layout/AdminLayout/Navigation/NavContent/NavBadge";
import * as actionTypes from "store/actions";
import T from "components/Common/text/T";
//Right Navigation Contents
function NavItem(props) {
  let itemTitle = props.item.title;
  if (props.item.icon) {
    itemTitle = (
      <span className="pcoded-mtext">
        <T>{props.item.title}</T>
      </span>
    );
  }

  let itemTarget = "";
  if (props.item.target) {
    itemTarget = "_blank";
  }

  let subContent;
  if (props.item.external) {
    subContent = (
      <NavLink to={props.item.url} target="_blank" rel="noopener noreferrer">
        <NavIcon items={props.item} />
        {itemTitle}
        <NavBadge layout={props.layout} items={props.item} />
      </NavLink>
    );
  } else {
    subContent = (
      <NavLink
        to={props.item.url}
        className="nav-link"
        exact={true}
        target={itemTarget}
      >
        <NavIcon items={props.item} />
        {itemTitle}
        <NavBadge layout={props.layout} items={props.item} />
      </NavLink>
    );
  }
  let mainContent = "";
  if (props.layout === "horizontal") {
    mainContent = <li onClick={props.onItemLeave}>{subContent}</li>;
  } else {
    if (props.windowWidth < 992) {
      mainContent = (
        <li className={props.item.classes} onClick={props.onItemClick}>
          {subContent}
        </li>
      );
    } else {
      mainContent = <li className={props.item.classes}>{subContent}</li>;
    }
  }

  return <>{mainContent}</>;
}

const mapStateToProps = (state) => {
  return {
    layout: state.layout,
    collapseMenu: state.collapseMenu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onItemClick: () => dispatch({ type: actionTypes.COLLAPSE_MENU }),
    onItemLeave: () => dispatch({ type: actionTypes.NAV_CONTENT_LEAVE }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NavItem)
);
