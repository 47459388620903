export const ENV_MODE_DEV = process.env.NODE_ENV === 'development';
export const ENV_MODE_PROD = process.env.NODE_ENV === 'production';

// const apiConfig = window.globalConfig;

// const BASE_API_URL = process.env.REACT_APP_API_URL;

/**
 * Default (live 배포 & Local)
 */
export const BASE_API_URL = process.env.REACT_APP_API_URL;
export const GOOGLE_AUTH_CLIENT_ID =
  process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID;
export const GOOGLE_AUTH_REDIRECT_URL =
  process.env.REACT_APP_GOOGLE_AUTH_REDIRECT_URI;
export const GOOGLE_AUTH_CLIENT_PASSWORD =
  process.env.REACT_APP_GOOGLE_AUTH_CLIENT_PASSWORD;

/**
 * 개발 Local 접속
 */

// export const BASE_API_URL = process.env.REACT_APP_API_URL_LOCAL;
// export const GOOGLE_AUTH_CLIENT_ID =
// process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID;

/**
 * 개발 환경 Live 접속
 */

// export const BASE_API_URL = process.env.REACT_APP_API_URL_LIVE;

/**
 * Field TEST Server Build
 * 필드 테스트 빌드 시 주석해제
 */

// export const BASE_API_URL = process.env.REACT_APP_API_URL_FIELD;
