import { createAction, createReducer, createSlice } from '@reduxjs/toolkit';
import { globalNotification } from 'lib/util';
import CODE from 'lib/codeName';
import { createFetchAction, createPromiseSaga } from 'store/util';
import { all, takeEvery, put, call } from 'redux-saga/effects';
import {
  // setLocalStorageItem,
  // removeLocalStorageItem,
  setSessionStorageItem,
} from 'lib/util';

import {
  checkEmailValid,
  checkEmailCode,
  checkDuplicateDofid,
  submitAuthSignup,
} from 'api/auth';
import { submitSignupService } from 'api/service';
import reducer from '../common';
import { useTransition } from 'react';
import { useTranslation } from 'react-i18next';

export const CHECK_DOF_ID = createFetchAction('signup/CHECK_DOF_ID');
export const CHECK_EMAIL_VALID = createFetchAction('signup/CHECK_EMAIL_VALID');
export const CHECK_EMAIL_CODE = createFetchAction('signup/CHECK_EMAIL_CODE');
export const SIGN_UP_AUTH = createFetchAction('signup/SIGN_UP_AUTH');
export const SIGN_UP_SERVICE = createFetchAction('signup/SIGN_UP_SERVICE');

const initialState = {
  userInfoUpdateResponse: null,
  checkDofid: null,
  checkEmail: null,
  checkEmailCode: null,
  authSignup: {},
  serviceSignup: {},
};

const slice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    //---------------------------------------- DOF_ID_CHECK ---------------------------------------- //
    //
    CHECK_DOF_ID_INIT: (state, { payload }) => {
      state.checkDofid = null;
    },
    CHECK_DOF_ID_REQUEST: (state, { payload }) => {},
    CHECK_DOF_ID_SUCCESS: (state, { payload }) => {
      state.checkDofid = true;
    },
    CHECK_DOF_ID_FAILURE: (state, { payload }) => {
      state.checkDofid = false;
    },

    //---------------------------------------- EMAIL_CHECK ---------------------------------------- //
    //
    CHECK_EMAIL_VALID_INIT: (state, { payload }) => {
      state.checkEmail = null;
    },
    CHECK_EMAIL_VALID_REQUEST: (state, { payload }) => {},
    CHECK_EMAIL_VALID_SUCCESS: (state, { payload }) => {
      state.checkEmail = true;
      const notifyModalData = {
        type: 'success',
        title: 'Send code',
        // content: "Send code to your email",
        content: 'We sent a verification code to your email.',
      };
      globalNotification(notifyModalData);
    },
    CHECK_EMAIL_VALID_FAILURE: (state, { payload }) => {
      state.checkEmail = false;
      let notifyModalData = {
        type: 'danger',
        title: 'Send code',
        content: '',
      };
      if (payload.status == CODE.status.conflict) {
        notifyModalData.content = 'Already exist email.';
      } else if (payload.status == CODE.status.badRequest) {
        notifyModalData.content =
          'You cannot re-register with a canceled account.\nPlease sign up using a different account.';
      } else {
        notifyModalData.content = 'Fail to send code.';
      }
      globalNotification(notifyModalData);
    },
    //---------------------------------------- EMAIL_CODE_CHECK ---------------------------------------- //
    //
    CHECK_EMAIL_CODE_INIT: (state, { payload }) => {
      state.checkEmailCode = null;
    },
    CHECK_EMAIL_CODE_REQUEST: (state, { payload }) => {},
    CHECK_EMAIL_CODE_SUCCESS: (state, { payload }) => {
      state.checkEmailCode = true;
      const notifyModalData = {
        type: 'success',
        title: 'Check code',
        // content: "Check your code",
        content: 'Your code is verified.',
      };
      globalNotification(notifyModalData);
    },
    CHECK_EMAIL_CODE_FAILURE: (state, { payload }) => {
      state.checkEmailCode = false;
      let notifyModalData = {
        type: 'danger',
        title: 'Check code',
        content: '',
      };

      if ((payload.data.emailResult = CODE.emailResult.notMatched)) {
        notifyModalData.content = 'No matched code';
      } else {
        notifyModalData.content = 'Fail to check code';
      }
      globalNotification(notifyModalData);
    },
    //---------------------------------------- AUTH_SIGN_UP ---------------------------------------- //
    //
    SIGN_UP_AUTH_INIT: (state, { payload }) => {
      state.authSignup.data = null;
      state.authSignup.success = null;
      state.authSignup.failure = null;
    },
    SIGN_UP_AUTH_REQUEST: (state, { payload }) => {
      console.log('AUTH_SIGN_UP_REQUEST', payload);
    },
    SIGN_UP_AUTH_SUCCESS: (state, { payload }) => {
      console.log('SIGN_UP_AUTH_SUCCESS', payload);
      state.authSignup.data = payload;
      state.authSignup.success = true;
      state.authSignup.failure = false;
      const notifyModalData = {
        type: 'success',
        title: 'Sign up',
        content: 'Success',
      };
      globalNotification(notifyModalData);
    },
    SIGN_UP_AUTH_FAILURE: (state, { payload }) => {
      state.authSignup.success = false;
      state.authSignup.failure = true;
      const notifyModalData = {
        type: 'danger',
        title: 'Sign up',
        content: 'Fail',
      };
      globalNotification(notifyModalData);
    },
    //---------------------------------------- SERVICE_SIGN_UP ---------------------------------------- //
    //
    // SIGN_UP_SERVICE_INIT: (state, { payload }) => {
    //   state.serviceSignup = null;
    // },
    SIGN_UP_SERVICE_REQUEST: (state, { payload }) => {
      console.log('SERVICE_SIGN_UP_REQUEST', payload);
      // state.serviceSignup.success = null;
      // state.serviceSignup.Failure = null;
    },
    SIGN_UP_SERVICE_SUCCESS: (state, { payload }) => {
      console.log('SIGN_UP_SERVICE_SUCCESS', payload);
      state.serviceSignup.data = payload.data;
      state.serviceSignup.success = true;
      state.serviceSignup.failure = false;
      const notifyModalData = {
        type: 'success',
        title: 'Sign up',
        content: 'Success',
      };
      globalNotification(notifyModalData);
    },
    SERVICE_SIGN_UP_FAILURE: (state, { payload }) => {
      state.serviceSignup.data = payload.data;
      state.serviceSignup.success = false;
      state.serviceSignup.failure = true;
      const notifyModalData = {
        type: 'danger',
        title: 'Sign up',
        content: 'Fail',
      };
      globalNotification(notifyModalData);
    },
  },
});

const dofidCheckSaga = createPromiseSaga(
  'signup/CHECK_DOF_ID',
  checkDuplicateDofid,
);

const checkEmailValidSaga = createPromiseSaga(
  'signup/CHECK_EMAIL_VALID',
  checkEmailValid,
);

const checkEmailCodeSaga = createPromiseSaga(
  'signup/CHECK_EMAIL_CODE',
  checkEmailCode,
);
const signupAuthSaga = createPromiseSaga(
  'signup/SIGN_UP_AUTH',
  submitAuthSignup,
);
const signUpServiceSaga = createPromiseSaga(
  'signup/SIGN_UP_SERVICE',
  submitSignupService,
);

export const actions = slice.actions;

export function* signupSaga() {
  yield all([
    takeEvery(CHECK_DOF_ID.REQUEST, dofidCheckSaga),
    takeEvery(CHECK_EMAIL_VALID.REQUEST, checkEmailValidSaga),
    takeEvery(CHECK_EMAIL_CODE.REQUEST, checkEmailCodeSaga),
    takeEvery(SIGN_UP_AUTH.REQUEST, signupAuthSaga),
    takeEvery(SIGN_UP_SERVICE.REQUEST, signUpServiceSaga),
  ]);
}

export default slice.reducer;
