import React, { Component, useEffect, useState } from 'react';

import ChatList from 'App/layout/AdminLayout/NavBar/NavRight/ChatList';
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  FormControl,
  DropdownButton,
  Dropdown,
} from 'react-bootstrap';

import components from 'store/constant';
import { useDidUpdateEffect, useShallowSelector } from 'lib/util';

import Avatar1 from 'assets/images/user/avatar-1.jpg';
import Avatar2 from 'assets/images/user/avatar-2.jpg';
import Avatar3 from 'assets/images/user/avatar-3.jpg';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
// import { AUTH_REFRESH } from 'store/modules/admin/AuthReducer';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
// import { MenuItem, Select, FormControl } from "@material-ui/core";
import MuiWrapper from 'components/Common/input/MuiWrapper';
import styled from 'styled-components';
import {
  // AuthActions,
  AppActions,
  SigninActions,
  UtilActions,
} from 'store/reduxActions';
import {
  getSessionStorageItem,
  clearSessionStorageItem,
  removeSessionStorageItem,
} from 'lib/util';
import { authRemove } from 'lib/util';
import { useTranslation } from 'react-i18next';
import T from 'components/Common/text/T';

// import { languageList } from 'lib/mapper';

function NavRight(props) {
  const { language, languageList, languageListSuccess } = useShallowSelector(
    state => ({
      language: state.app.language,
      languageList: state.utilReducer.languageList.list,
      languageListSuccess: state.utilReducer.languageList.success,
    }),
  );

  const history = useHistory();
  const dispatch = useDispatch();
  const [translationLanguage, setTranslationLanguage] = useState(language);

  const logout = async () => {
    // window.localStorage.removeItem('auth');
    // authRemove();
    // AuthActions.AUTH_REFRESH_REQUEST({});
    clearSessionStorageItem();
    SigninActions.LOGOUT_RESET();
    history.push(`/login`);
  };
  const { t } = useTranslation();

  useEffect(() => {
    UtilActions.LANGUAGE_LIST_REQUEST();
  }, []);

  useDidUpdateEffect(() => {
    if (!languageListSuccess) return;
    console.log('HDS] fetch languageList ________ ', languageList);
  }, [languageListSuccess]);

  return (
    <>
      <ul className="navbar-nav ml-auto">
        {/* <li>
          <Styled.LanguageSelecter>
            <MuiWrapper className="navbar-language">
              <FormControl fullWidth variant="outlined">
                <Select
                  //   disabled={!isModify.value}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    PaperProps: {
                      style: {
                        maxHeight: "50%",
                      },
                    },
                  }}
                  displayEmpty
                  name="country"
                  value={translationLanguage}
                  onChange={(e) => {
                    setTranslationLanguage(e.target.value);
                    AppActions.change_language(e.target.value);
                  }}
                >
                  <MenuItem value="en">ENGLISH</MenuItem>
                  <MenuItem value="ko">한국어</MenuItem>
                </Select>
              </FormControl>
            </MuiWrapper>
          </Styled.LanguageSelecter>
        </li> */}
        {/* <li>
                    <Dropdown alignRight={!props.rtlLayout}>
                        <Dropdown.Toggle variant={'link'} id="dropdown-basic">
                            <i className="icon feather icon-bell"/>
                        </Dropdown.Toggle>
                        <Dropdown.Menu alignRight className="notification">
                            <div className="noti-head">
                                <h6 className="d-inline-block m-b-0">Notifications</h6>
                                <div className="float-right">
                                    <a href={components.BLANK_LINK} className="m-r-10">mark as read</a>
                                    <a href={components.BLANK_LINK}>clear all</a>
                                </div>
                            </div>
                            <ul className="noti-body">
                                <li className="n-title">
                                    <p className="m-b-0">NEW</p>
                                </li>
                                <li className="notification">
                                    <div className="media">
                                        <img className="img-radius" src={Avatar1} alt="Generic placeholder"/>
                                        <div className="media-body">
                                                <p>
                                                    <strong>{userInfo.userName}</strong>
                                                <span className="n-time text-muted">
                                                <i className="icon feather icon-clock m-r-10"/>30 min
                                                </span>
                                                </p>
                                            <p>New ticket Added</p>
                                        </div>
                                    </div>
                                </li>
                                <li className="n-title">
                                    <p className="m-b-0">EARLIER</p>
                                </li>
                                <li className="notification">
                                    <div className="media">
                                        <img className="img-radius" src={Avatar2} alt="Generic placeholder"/>
                                        <div className="media-body">
                                            <p><strong>Joseph William</strong><span className="n-time text-muted"><i
                                                className="icon feather icon-clock m-r-10"/>30 min</span></p>
                                            <p>Prchace New Theme and make payment</p>
                                        </div>
                                    </div>
                                </li>
                                <li className="notification">
                                    <div className="media">
                                        <img className="img-radius" src={Avatar3} alt="Generic placeholder"/>
                                        <div className="media-body">
                                            <p><strong>Sara Soudein</strong><span className="n-time text-muted"><i
                                                className="icon feather icon-clock m-r-10"/>30 min</span></p>
                                            <p>currently login</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div className="noti-footer">
                                <a href={components.BLANK_LINK}>show all</a>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </li> */}
        {/* <li className={props.rtlLayout ? 'm-r-15' : 'm-l-15'}>
                    <a href={components.BLANK_LINK} className="displayChatbox" onClick={() => {setState({listOpen: true});}}><i className="icon feather icon-mail"/></a>
                </li> */}
        <li>
          {/* <Form.Control size="lg" as="select" className="mb-3"> */}
          <Form.Control
            as="select"
            className=""
            value={language}
            onChange={e => {
              setTranslationLanguage(e.target.value);
              AppActions.change_language(e.target.value);
            }}
          >
            {/* <option value="en">ENGLISH</option>
            <option value="ko">한국어</option>
            <option value="jp"></option> */}
            {languageList?.map((item, index) => {
              return (
                <option value={item.language} key={index}>
                  {item.languageAlias}
                </option>
              );
            })}
          </Form.Control>
        </li>

        {/* <li>
          <Dropdown alignRight={!props.rtlLayout} className="drp-user">
            <Dropdown.Toggle variant={"link"} id="dropdown-basic">
              <i className="icon feather icon-settings" />
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight className="profile-notification">
              <div className="pro-head">
                <span>{userInfo.userName}</span>
                <Link
                  to={components.BLANK_LINK}
                  className="dud-logout"
                  title="Logout"
                  onClick={() => logout()}
                >
                  <i className="feather icon-log-out" />
                </Link>
              </div>
              <ul className="pro-body">
                <li>
                  <Link to="/auth/mypage" className="dropdown-item">
                    <i className="feather icon-user" /> <T>GLOBAL_MYPAGE</T>
                  </Link>
                </li>
                <li>
                  <a className="dropdown-item" onClick={() => logout()}>
                    <i className="feather icon-lock" /> <T>GLOBAL_LOGOUT</T>
                  </a>
                </li>
              </ul>
            </Dropdown.Menu>
          </Dropdown>
        </li> */}
      </ul>
      {/* <ChatList
        listOpen={state.listOpen}
        closed={() => {
          setState({ listOpen: false });
        }}
      /> */}
    </>
  );
}

const Styled = {
  LanguageSelecter: styled.div`
    .navbar-language {
      height: 30px;
    }
  `,
};

export default NavRight;
