import { axs } from "api/config/axiosConfig";
import apiUrl from "lib/url";

export function fetchInitCountryList(payload) {
  console.log("fetchInitCountry");
  return axs({ url: apiUrl.COUNTRY_LIST, method: "get", params: payload });
}

export function fetchRegionList(payload) {
  console.log("fetchRegion");
  return axs({ url: apiUrl.REGION_LIST, method: "get", params: payload });
}

export function fetchLanguageList(payload) {
  console.log("fetchLanguageList");
  return axs({ url: apiUrl.LANGUAGE_LIST, method: "get", params: payload });
}

export function fetchLegalList(payload) {
  console.log("fetchLegalList");
  return axs({ url: apiUrl.LEGAL_LIST, method: "get", params: payload });
}
