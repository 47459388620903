import { createStore, applyMiddleware } from "redux";
import logger from "redux-logger";
import reducers, { rootSaga } from "./modules";
import { persistStore } from "redux-persist";
import createSagaMiddleware from "redux-saga";
import { ENV_MODE_DEV } from "lib/setting";
const sagaMiddleware = createSagaMiddleware();

let middlewares = [sagaMiddleware];
if (ENV_MODE_DEV) middlewares = [...middlewares, logger];

export const store = createStore(reducers, applyMiddleware(...middlewares));
export const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);
// export default store;
