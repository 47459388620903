import { axs } from 'api/config/axiosConfig';
import apiUrl from 'lib/url';

export function fetchCheckEmail(payload) {
  console.log('fetchCheckEmail', payload);
  return axs({ url: apiUrl.EMAIL_CHECK, method: 'post', data: payload });
}

export function fetchCheckCode(payload) {
  console.log('fetchCheckCode', payload);
  return axs({ url: apiUrl.CODE_CHECK, method: 'put', data: payload });
}

export function fetchUserSignup(payload) {
  console.log('fetchSignup', payload);
  return axs({ url: apiUrl.SIGN_UP, method: 'post', data: payload });
}

export function fetchUserLogin(payload) {
  console.log('fetchUserLogin', payload);
  return axs({ url: apiUrl.LOG_IN, method: 'post', data: payload });
}

export function fetchUserScopeRegist(payload) {
  console.log('fetchUserScopeRegist', payload);
  return axs({ url: apiUrl.SCOPE_REGIST, method: 'post', data: payload });
}

export function fetchUserRequireScopeList(payload) {
  console.log('fetchUserRequireScopeList', payload);
  return axs({
    url: apiUrl.REQUIRE_SCOPE_LIST,
    method: 'get',
    params: payload,
  });
}

export function fetchUserOauthClientDelete(payload) {
  console.log('fetchUserOauthClientDelete', payload);
  return axs({
    url: apiUrl.OAUTH_CLIENT_DELETE,
    method: 'delete',
    data: payload,
  });
}

export function fetchCheckPwResetEmail(payload) {
  console.log('fetchCheckPwResetEmail', payload);
  return axs({
    url: apiUrl.PW_RESET_EMAIL_CHECK,
    method: 'post',
    data: payload,
  });
}

export function fetchUserTempLogin(payload) {
  console.log('fetchUserTempLogin', payload);
  return axs({ url: apiUrl.TEMP_LOG_IN, method: 'post', data: payload });
}

export function fetchDeletePwReset(payload) {
  console.log('fetchDeletePwReset', payload);
  return axs({ url: apiUrl.PW_RESET_DELETE, method: 'delete', data: payload });
}

export function fetchUpdatePw(payload) {
  console.log('fetchUpdatePw', payload);
  return axs({ url: apiUrl.PW_RESET, method: 'put', data: payload });
}

export function fetchServiceCheck(payload) {
  console.log('fetchServiceCheck', payload);
  return axs({ url: apiUrl.SERVICE_CHECK, method: 'get', params: payload });
}

export function fetchLoginTokenCheck(payload) {
  console.log('fetchLoginTokenCheck', payload);
  return axs({ url: apiUrl.CHECK_TOKEN, method: 'post', data: payload });
}

// export function fetchLoginTokenCheck(payload) {
//   console.log("fetchLoginTokenCheck", payload);
//   return axs({ url: apiUrl.LOGIN_TOKEN_CHECK, method: "get", params: payload });
// }

export function fetchSingnUpUserInfo(payload) {
  console.log('fetchSingnUpUserInfo', payload);
  return axs({ url: apiUrl.SIGN_UP_USER_INFO, method: 'get', params: payload });
}

export function fetchServiceUserInfo(payload) {
  console.log('fetchServiceUserInfo', payload);
  return axs({ url: apiUrl.SERVICE_USER_INFO, method: 'get', params: payload });
}

export function fetchCheckNickname(payload) {
  console.log('fetchSingnUpUserInfo', payload);
  return axs({ url: apiUrl.NICKNAME_CHECK, method: 'get', params: payload });
}

export function updateServiceUserInfo(payload) {
  console.log('updateServiceUserInfo', payload);

  return axs({
    url: apiUrl.SERVICE_USER_INFO,
    method: 'put',
    data: payload.data,
    params: payload.params,
  });
}

export function updateServicePassword(payload) {
  console.log('updateServicePassword', payload);
  return axs({
    url: apiUrl.SERVICE_PASSWORD_CHANGE,
    method: 'put',
    data: payload,
  });
}

// V2
export function loginAuthUser(payload) {
  console.log('[API_V2]_[ loginAuthUser ] : ', payload);
  return axs({
    url: apiUrl.LOGIN_AUTH_USER,
    method: 'post',
    data: payload,
  });
}

export function fetchUserProfile(payload) {
  console.log('[API_V2]_[ fetchUserProfile ] : ', payload);
  return axs({
    url: apiUrl.USER_PROFILE,
    method: 'get',
    params: payload,
  });
}

export function updateUserProfile(payload) {
  console.log('[API_V2]_[ updateUserProfile ] : ', payload);
  return axs({
    url: apiUrl.USER_PROFILE,
    method: 'put',
    data: payload,
  });
}

export function updateUserPassword(payload) {
  console.log('[API_V2]_[ updateUserPassword ] : ', payload);
  return axs({
    url: apiUrl.CHANGE_USER_PASSWORD,
    method: 'put',
    data: payload,
  });
}

export function fetchLinkedServcice(payload) {
  console.log('[API_V2]_[ fetchLinkedServcice ] : ', payload);
  return axs({
    url: apiUrl.LINKED_SERVICE,
    method: 'get',
    params: payload,
  });
}

export function signOut(payload) {
  console.log('[API_V2]_[ signOut ] : ', payload);
  return axs({
    url: apiUrl.SIGN_OUT,
    method: 'put',
    data: payload,
  });
}

export function deleteUserProfile(payload) {
  console.log('deleteUserProfile', payload);
  return axs({ url: apiUrl.USER_WITHDRAWAL, method: 'delete', data: payload });
}

export function withdrawalUserSurvey(payload) {
  console.log('withdrawalUserSurvey', payload);
  return axs({ url: apiUrl.USER_SURVEY, method: 'post', data: payload });
}

export function fetchWithdrawalPoint(payload) {
  console.log('fetchWithdrawalPoint');
  return axs({
    url: apiUrl.WITHDRAWAL_POINT,
    method: 'get',
    params: payload,
  });
}

export function fetchWithdrawalProject(payload) {
  console.log('fetchWithdrawalProject');
  return axs({
    url: apiUrl.WITHDRAWAL_PROJECT,
    method: 'get',
    params: payload,
  });
}
