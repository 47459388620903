import React, { Suspense, useEffect } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import 'react-notifications-component/dist/theme.css';
import { connect, useDispatch, useSelector, shallowEqual } from 'react-redux';
import * as actionTypes from 'store/actions';
import { LeftTabRoutes } from 'routes';
import Fullscreen from 'react-full-screen';
import Navigation from 'App/layout/AdminLayout/Navigation';
import NavBar from 'App/layout/AdminLayout/NavBar';
import Breadcrumb from 'App/layout/AdminLayout/Breadcrumb';
import Loader from 'App/layout/Loader';

import './app.scss';
import { getSessionStorageItem, clearSessionStorageItem } from 'lib/util';
import { useShallowSelector } from 'lib/util';
import { UserAction } from 'store/reduxActions';

// const AuthMenu = React.lazy(() => import('containers/auth/AuthMenuContainer'));

function AdminLayout(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const Error = React.lazy(() => import('containers/error/Error'));

  const { fetchProfile } = useShallowSelector(state => ({
    fetchProfile: state.userReducer.fetchProfile.data,
  }));

  const fullScreenExitHandler = () => {
    if (
      !document.fullscreenElement &&
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement
    ) {
      this.props.onFullScreenExit();
    }
  };

  const mobileOutClickHandler = () => {
    if (this.props.windowWidth < 992 && this.props.collapseMenu) {
      this.props.onComponentWillMount();
    }
  };

  /* full screen exit call */
  //   document.addEventListener('fullscreenchange', fullScreenExitHandler);
  //   document.addEventListener('webkitfullscreenchange', fullScreenExitHandler);
  //   document.addEventListener('mozfullscreenchange', fullScreenExitHandler);
  //   document.addEventListener('MSFullscreenChange', fullScreenExitHandler);

  console.log('현재 URL', window.location.pathname);
  console.log('props.location', props.location);
  console.log('props.location.pathname', props.location.pathname);
  console.log('props.defaultPath', props.defaultPath);

  const menu = LeftTabRoutes.map((route, index) => {
    return route.component ? (
      <Route
        key={index}
        path={route.path}
        exact={route.exact}
        name={route.name}
        render={props => {
          console.log('route.path : ', route.path);

          if (getSessionStorageItem('_accessToken')) {
            return <route.component {...props} />;
          } else {
            if (route.path === '/withdrawal/success') {
              return <route.component {...props} />;
              // history.push('/withdrawal/success');
            } else {
              history.push('/login');
            }
          }
          // if(authGet()){
          //     if(permissionCheck(permission, route.permission)){
          //         return <route.component {...props} />;
          //     }else{
          //         return null;
          //     }
          // }else {
          //     console.log(auth, ' window.localStorage.getItem("auth") ', authGet());
          //     return redirectLogin;
          // }
        }}
      />
    ) : (
      <Route component={Error} />
    );
  });

  useEffect(() => {
    if (!getSessionStorageItem('_accessToken')) {
      clearSessionStorageItem();
      history.push('/login');
    } else {
      //   AuthLoginAction.AUTH_USERINFO_REQUEST();
      UserAction.FETCH_USER_PROFILE_REQUEST();
    }
    // if(window.location.pathname == "/login"){
    //     history.push("/error/404");
    // }
    // history.push('/login');
  }, []);

  return (
    <>
      <Fullscreen>
        <Navigation />
        <NavBar />
        <div
          className="pcoded-main-container"
          onClick={() => mobileOutClickHandler}
        >
          <div className="pcoded-wrapper">
            <div className="pcoded-content">
              <div className="pcoded-inner-content">
                <Breadcrumb />
                <div className="main-body">
                  <div className="page-wrapper">
                    <Suspense fallback={<Loader />}>
                      <Switch>
                        {menu}
                        <Route path="*">
                          <Redirect to="/auth/home" />
                        </Route>
                        {/* <Route component={Error}/> */}
                      </Switch>
                    </Suspense>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fullscreen>
    </>
  );
}

const mapStateToProps = state => {
  return {
    // auth : state.authReducer.auth,
    accessToken: state.signinReducer.accessToken,
    defaultPath: state.commonReducer.defaultPath,
    isFullScreen: state.commonReducer.isFullScreen,
    collapseMenu: state.commonReducer.collapseMenu,
    configBlock: state.commonReducer.configBlock,
    layout: state.commonReducer.layout,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFullScreenExit: () => dispatch({ type: actionTypes.FULL_SCREEN_EXIT }),
    onComponentWillMount: () => dispatch({ type: actionTypes.COLLAPSE_MENU }),
    // onInitIndication: indicationForamt => dispatch(INIT_INDICATION(indicationForamt)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout);
