import React from 'react';
import styled from 'styled-components';

export default function CustomText(props) {
  const {
    children,
    fontSize,
    fontColor,
    fontWeight,
    fontType,
    fontTypeM = fontType,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
  } = props;
  return (
    <Styled.CustomText
      fontSize={fontType?.fontSize || fontSize}
      fontColor={fontColor}
      fontWeight={fontType?.fontWeight || fontWeight}
      marginTop={marginTop}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      marginRight={marginRight}
      data-component-name="CustomText"
    >
      {children}
    </Styled.CustomText>
  );
}

const Styled = {
  CustomText: styled.p`
    font-size: ${props => props.fontSize && `${props.fontSize}px`};
    color: ${props => props.fontColor && `${props.fontColor}`};
    font-weight: ${props => props.fontWeight && `${props.fontWeight}`};
    margin-top: ${props => props.marginTop && `${props.marginTop}px`};
    margin-bottom: ${props => props.marginBottom && `${props.marginBottom}px`};
    margin-left: ${props => props.marginLeft && `${props.marginLeft}px`};
    margin-right: ${props => props.marginRight && `${props.marginRight}px`};
  `,
};
