import React from 'react';
import CODE from 'lib/codeName';

// const AuthMenu = React.lazy(() => import('containers/auth/AuthMenuContainer'));

const Home = React.lazy(() => import('containers/home/DashboardContainer'));
const Logout = React.lazy(() => import('containers/logout/LogoutContainer'));
const MyProfile = React.lazy(() => import('containers/my/MyProfileContainer'));
const WithdrawalInfo = React.lazy(() =>
  import('containers/my/WithdrawalInfoContainer'),
);
const WithdrawalNotInfo = React.lazy(() =>
  import('containers/my/WithdrawalNotInfoContainer'),
);
const WithdrawalPassword = React.lazy(() =>
  import('containers/my/WithdrawalPasswordContainer'),
);
const WithdrawalSuccess = React.lazy(() =>
  import('containers/my/WithdrawalSuccessContainer'),
);
const Password = React.lazy(() =>
  import('containers/password/PasswordChangeContainer'),
);
const LinkedService = React.lazy(() =>
  import('containers/linked/LinkedServiceContainer'),
);

const LeftTabRoutes = [
  { path: '/auth/home', exact: true, name: 'Home', component: Home },
  {
    path: '/auth/mypage',
    exact: true,
    name: 'MyProfile',
    component: MyProfile,
  },
  {
    path: '/withdrawal/info',
    exact: true,
    name: 'WithdrawalInfo',
    component: WithdrawalInfo,
  },
  {
    path: '/withdrawal/notinfo',
    exact: true,
    name: 'WithdrawalNotInfo',
    component: WithdrawalNotInfo,
  },
  {
    path: '/withdrawal/password',
    exact: true,
    name: 'WithdrawalPassword',
    component: WithdrawalPassword,
  },
  {
    path: '/withdrawal/success',
    exact: true,
    name: 'WithdrawalSuccess',
    component: WithdrawalSuccess,
  },

  {
    path: '/auth/password/change',
    exact: true,
    name: 'Password',
    component: Password,
  },
  {
    path: '/auth/linked/service',
    exact: true,
    name: 'LinkedService',
    component: LinkedService,
  },
  { path: '/auth/logout', exact: true, name: 'Logout', component: Logout },
];

export default LeftTabRoutes;
