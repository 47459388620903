export default {
    res : {
        fail : 0,
        success: 1, 
        notFound : 2,
        notEnoughParam : 3,
        noAffected: 4,
        err: 5,
        notEnoughPoint : 6
    },
    status : {
        success : 200,
        created : 201,
        accepted : 202,
        noContent : 204,
        badRequest : 400,
        unAuthorized : 401,
        forbidden : 403,
        notFound : 404,
        methodNotAllowed : 405,
        conflict : 409,
        tooManyRequest : 429,
        serverError : 500
    },
    passwordChangeResult:{
      notMatched : 0,
      success : 1,
      samePassword : 2
    },
    tokenResult : {
      notMatched : 0,
      success : 1,      
      expired : 2,
    },
    scopeResult:{
        noScope : 0,
        aleadyExist : 1,
        notNeed : 2
    },
    scopeName:{
        "auth.token.refresh" : "토큰 정보",
        "member.info.public" : "공개 프로필"
    },
    emailResult : {
        notAuth: 0,
        success : 1,
        aleadyExist : 2,
        sendFail : 3,
        notMatched : 4,
        expired : 5,
        isSendNotAuth : 6
    },
    projectCode:{
        0: 'created',
        1 : 'uploaded',
        2: 'downloaded',
        3: 'completed'
    },
    userStatus : {
        0: '정상',
        1: '정지',
        2 : '탈퇴' 
    },
    purchaseState : {
        0: "결제대기",
        1: "결제완료",
        2: "오류"
    },
    productType : {
        0: "Cloud",
        1: "Software",
        2 : "Warranty"
    } ,
    permission : {
        newAdmin : 1,
        updateAdmin : 2,
        read : 3,
        update : 4,
        delete : 5,
        add : 6
    },
    historyType :{
        view : 0,
        add : 1,
        update : 2,
        delete : 3
    },
    historyTypeName:{
        0: "VIEW",
        1: "ADD",
        2: "UPDATE",
        3: "DELETE"
    },
    projectType : {
        "all" : 0,
        "sync" : 1,
        "bridge" : 2
    },
    syncStageName : {
        0: "CREATE",
        1:"UPLOADED",
        2: "DOWNLOADED",
        3: "COMPLETE"
    },
    bridgeStageName : {
        0: "CREATE",
        1: "MATCHING",
        2: "WORKING",
        3: "DONE",
        4: "COMPLETE"
    },
    location : {
        adminDetail : (data)=> {return `/detail/${data}` },
        productDetail : (data) => { return `/store/product/detail/${data}` },
        userDetail : (data) => { return  `/user/detail/${data}`}
    }
};
