import { axs } from 'api/config/axiosConfig';
import apiUrl from 'lib/url';

export function serviceLogin(payload) {
  console.log('[POST_API]_serviceLogin__ : ', payload);
  return axs({
    url: apiUrl.LOGIN_SERVCIE,
    method: 'post',
    data: payload,
  });
}

export function submitSignupService(payload) {
  console.log('[POST_API]_serviceSignup__ : ', payload);
  return axs({ url: apiUrl.SIGN_UP_SERVICE, method: 'post', data: payload });
}

export function fetchUserOauthClientDelete11(payload) {
  console.log('fetchUserOauthClientDelete', payload);
  return axs({
    url: apiUrl.OAUTH_CLIENT_DELETE,
    method: 'delete',
    data: payload,
  });
}

export function fetchCheckPwResetEmail11(payload) {
  console.log('fetchCheckPwResetEmail', payload);
  return axs({
    url: apiUrl.PW_RESET_EMAIL_CHECK,
    method: 'post',
    data: payload,
  });
}

export function fetchUpdatePw11(payload) {
  console.log('fetchUpdatePw', payload);
  return axs({ url: apiUrl.PW_RESET, method: 'put', data: payload });
}
