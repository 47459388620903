import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { all, fork } from 'redux-saga/effects';
import storage from 'redux-persist/lib/storage';

// import authReducer, {authSaga} from './admin/AuthReducer';
// import historyReducer, {historySaga} from './common/HistoryReducer';
import configReducer, { initSaga } from './common/ConfigReducer';
import modalReducer from './common/ModalReducer';
import commonReducer from './common';

// V2
import utilReducer, { utilSaga } from './util/UtilReducer';
import serviceReducer, { serviceSaga } from './service/ServiceReducer';
import authReducer, { authSaga } from './auth/AuthReducer';
import signupReducer, { signupSaga } from './signup/SignupReducer';
import signinReducer, { signinSaga } from './signin/SigninReducer';
import userReducer, { userSaga } from './user/UserReducer';

import app, { appSaga } from './app';

const psersistConfig = {
  key: 'root',
  storage,
  whitelist: [],
  blacklist: [],
};

const reducers = combineReducers({
  app,
  configReducer,
  modalReducer,
  commonReducer,

  serviceReducer,
  utilReducer,
  authReducer,
  signupReducer,
  signinReducer,
  userReducer,
});

export function* rootSaga() {
  yield all([
    // fork(authSaga),
    // fork(historySaga),
    fork(appSaga),
    fork(initSaga),

    fork(serviceSaga),
    fork(utilSaga),
    fork(authSaga),
    fork(signupSaga),
    fork(signinSaga),
    fork(userSaga),
  ]);
}

export default reducers;
