import axios from 'axios';
// const appConfig = window.globalConfig;
import CODE from 'lib/codeName';
import { BASE_API_URL } from 'lib/setting';
// import {authRemove} from 'lib/util';
import {
  getSessionStorageItem,
  removeSessionStorageItem,
  setSessionStorageItem,
} from 'lib/util';

export function axs(payload) {
  const AUTH_API = BASE_API_URL;
  console.log('axs ', payload);
  const { data, url, method, params } = payload;

  let axiosData = {}; //data?data:{};

  axiosData.headers = {
    'x-access-token': getSessionStorageItem('_accessToken'),
    location: payload.location ? payload.location : window.location.pathname,
    'Content-type': 'application/json; charset=UTF-8',
  };
  axiosData.url = `${AUTH_API}${url}`;
  axiosData.method = method;
  axiosData.params = params ? params : '';
  axiosData.data = data;
  console.log('axiosData ', axiosData);
  return axios(axiosData)
    .then(response => {
      console.log('axios response', response);
      if (
        response.data?.headers &&
        response.data.headers.hasOwnProperty('x-access-token') &&
        response.data.headers.authState == 1
      ) {
        setSessionStorageItem('_accessToken', {
          accessToken: response.data.headers['x-access-token'],
        });
      }
      response.data.payload = axiosData.data;
      return response;
    })
    .catch(error => {
      if (error.response) {
        console.log('axios error.response ', error.response.status);
        if (error.response.status == CODE.status.unAuthorized) {
          // window.localStorage.removeItem('auth');
          // authRemove();
          removeSessionStorageItem('_accessToken');
        }
        // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        return error.response;
      } else if (error.request) {
        // 요청이 이루어 졌으나 응답을 받지 못했습니다.
        // `error.request`는 브라우저의 XMLHttpRequest 인스턴스 또는
        // Node.js의 http.ClientRequest 인스턴스입니다.
        console.log(error.request);
      } else {
        // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
        console.log('Error', error.message);
      }
      // const { response = {}, request = '', message = '' } = error;
      // const { data = null, status = '', headers = null } = response;
      // const responseStatus = data?.status ? data.status : status;
      // const responseMessage = data?.msg ? data.msg : message;

      // throw {
      //   status: responseStatus,
      //   message: responseMessage,
      //   payload: axiosData.data,
      // };
    });
}
