import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import config from 'lib/config';
import navigation from 'lib/menu-items';
import components from "store/constant";


function Breadcrumb() {
    const[state, setState] = React.useState({
        main: [],
        item: []
    });

    const componentDidMount=()=> {
        (navigation.items).map((item, index) => {
            if (item.type && item.type === 'group') {
                getCollapse(item, index);
            }
            return false;
        });
    };

    const componentWillReceiveProps = () => {
        (navigation.items).map((item, index) => {
            if (item.type && item.type === 'group') {
                getCollapse(item);
            }
            return false;
        });
    };

    const getCollapse = (item) => {
        if (item.children) {
            (item.children).filter( collapse => {
                if (collapse.type && collapse.type === 'collapse') {
                    getCollapse(collapse,);
                } else if (collapse.type && collapse.type === 'item') {
                    if (document.location.pathname === config.basename+collapse.url) {
                        setState({item: collapse, main: item});
                    }
                }
                return false;
            });
        }
    };

    let main, item;
    let breadcrumb = '';
    let title = 'Welcome';
    if (state.main && state.main.type === 'collapse') {
        main = (
            <li className="breadcrumb-item">
                <a href={components.BLANK_LINK}>{state.main.title}</a>
            </li>
        );
    }

    if (state.item && state.item.type === 'item') {
        title = state.item.title;
        item = (
            <li className="breadcrumb-item">
                <a href={components.BLANK_LINK}>{title}</a>
            </li>
        );

        if(state.item.breadcrumbs !== false) {
            breadcrumb = (
                <div className="page-header">
                    <div className="page-block">
                        <div className="row align-items-center">
                            <div className="col-md-12">
                                <div className="page-header-title">
                                    <h5 className="m-b-10">{title}</h5>
                                </div>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/"><i className="feather icon-home"/></Link>
                                    </li>
                                    {main}
                                    {item}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

    }

    return (
        <>
            {breadcrumb}
        </>
    );
}

export default Breadcrumb;