import { createAction, createReducer, createSlice } from '@reduxjs/toolkit';
import { globalNotification } from 'lib/util';
import CODE from 'lib/codeName';
import moment from 'moment';
import { createFetchAction, createPromiseSaga } from 'store/util';
import { all, takeEvery, put, call } from 'redux-saga/effects';
import {
  // setLocalStorageItem,
  // removeLocalStorageItem,
  setSessionStorageItem,
  setCookie,
  getCookie,
  keys,
} from 'lib/util';
import {
  // AuthActions
  UserLoginAction,
} from 'store/reduxActions';

import { serviceLogin } from 'api/service';

export const SERVICE_LOGIN = createFetchAction('service/SERVICE_LOGIN');

const initialState = {
  loginResponse: null,
  accessToken: '',
  // refreshToken : '',
  // tokenInfo : {},
  userInfo: {},
  userInfoResponse: null,
  serviceLogin: {},
  // clientId : '',
};

const slice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    SERVICE_LOGIN_INIT: (state, { payload }) => {
      // console.log("SERVICE_LOGIN_REQUEST ", state);
      state.serviceLogin.success = null;
      state.serviceLogin.failure = null;
      state.serviceLogin.redirectUri = null;
    },
    SERVICE_LOGIN_REQUEST: (state, { payload }) => {
      // console.log("SERVICE_LOGIN_REQUEST ", state);
    },
    SERVICE_LOGIN_SUCCESS: (state, { payload }) => {
      // console.log("USER_LOGIN_SUCCESS", payload);
      state.serviceLogin.success = true;
      state.serviceLogin.failure = false;
      state.serviceLogin.redirectUri = payload.data.redirectUrl;

      // let resentLogin = getCookie(keys.resent_login)?.split(",") || [];
      // // 증복 제거
      // resentLogin = new Set([...resentLogin, payload.data.userInfo.email]);
      // setCookie(keys.resent_login, Array.from(resentLogin));
    },
    SERVICE_LOGIN_FAILURE: (state, { payload }) => {
      // console.log("AUTH_LOGIN_FAILURE", payload);
      state.serviceLogin.success = false;
      state.serviceLogin.failure = true;
      state.serviceLogin.redirectUri = payload.data.redirectUrl;
      // const notifyModalData = {
      //     type : 'danger',
      //     title : '로그인 실패',
      //     content : ''
      // };

      // notifyModalData.content = payload.data?.msg||'실패';
      // globalNotification(notifyModalData);
      if (payload.data.redirectUrl) {
        let msg = payload.data?.msg || 'Failed.';
        alert(msg + ' Back to ' + payload.data.redirectUrl);
      } else {
        const notifyModalData = {
          type: 'danger',
          title: '로그인 실패',
          content: '',
        };

        notifyModalData.content = payload.data?.msg || '실패';
        globalNotification(notifyModalData);
      }
      // state.isExpired = payload.data.isExpired;
      // state.scopeResult = payload.data.scopeResult;
      // state.redirectUri = payload.data.redirectUrl;
      // state.serviceLoginSuccess = false;
    },
  },
});

const serviceLoginSaga = createPromiseSaga('service/SERVICE_LOGIN', serviceLogin);

export const actions = slice.actions;

export function* serviceSaga() {
  yield all([takeEvery(SERVICE_LOGIN.REQUEST, serviceLoginSaga)]);
}

export default slice.reducer;
