import React from 'react';
import components from 'store/constant';
import { Link } from 'react-router-dom';
import T from 'components/Common/text/T';
import { icon_dof } from 'components/base/images';

function navLogo(props) {
  let toggleClass = ['mobile-menu'];
  if (props.collapseMenu) {
    toggleClass = [...toggleClass, 'on'];
  }

  return (
    <>
      <div className="navbar-brand header-logo">
        {/* <Link to={components.BLANK_LINK} className="b-brand"> */}
        <Link to="/auth/home" className="b-brand">
          <div className="b-bg">
            <img src={icon_dof} className="dof_icon" />
            {/* <i className="feather icon-trending-up" /> */}
            {/* <img
                            src={`${process.env.PUBLIC_URL}/favicon.ico`}
                            loading="lazy"
                        /> */}
          </div>
          <span className="b-title">
            <T>SERVICE_NAME</T>
          </span>
        </Link>
        <Link
          to={components.BLANK_LINK}
          className={toggleClass.join(' ')}
          id="mobile-collapse"
          onClick={props.onToggleNavigation}
        >
          <span />
        </Link>
      </div>
    </>
  );
}

export default navLogo;
