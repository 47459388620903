import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import components from "store/constant";
import * as actionTypes from "store/actions";
import NavIcon from "App/layout/AdminLayout/Navigation/NavContent/NavIcon";
import NavBadge from "App/layout/AdminLayout/Navigation/NavContent/NavBadge";
import NavItem from "App/layout/AdminLayout/Navigation/NavContent/NavItem";
import LoopNavCollapse from "App/layout/AdminLayout/Navigation/NavContent/NavCollapse";
import T from "components/Common/text/T";

function NavCollapse(props) {
  const componentDidMount = () => {
    const currentIndex = document.location.pathname
      .toString()
      .split("/")
      .findIndex((id) => id === props.collapse.id);
    if (currentIndex > -1) {
      props.onCollapseToggle(props.collapse.id, this.props.type);
    }
  };
  const { isOpen, isTrigger } = props;

  let navItems = "";
  if (props.collapse.children) {
    const collapses = props.collapse.children;
    navItems = Object.keys(collapses).map((item) => {
      item = collapses[item];
      switch (item.type) {
        case "collapse":
          return <LoopNavCollapse key={item.id} collapse={item} type="sub" />;
        case "item":
          return <NavItem layout={props.layout} key={item.id} item={item} />;
        default:
          return false;
      }
    });
  }

  let itemTitle = props.collapse.title;
  if (props.collapse.icon) {
    itemTitle = (
      <span className="pcoded-mtext">
        <T>{props.collapse.title}</T>
      </span>
    );
  }

  let navLinkClass = ["nav-link"];

  let navItemClass = ["nav-item", "pcoded-hasmenu"];
  const openIndex = isOpen.findIndex((id) => id === props.collapse.id);
  if (openIndex > -1) {
    navItemClass = [...navItemClass, "active"];
    if (props.layout !== "horizontal") {
      navLinkClass = [...navLinkClass, "active"];
    }
  }

  const triggerIndex = isTrigger.findIndex((id) => id === props.collapse.id);
  if (triggerIndex > -1) {
    navItemClass = [...navItemClass, "pcoded-trigger"];
  }

  const currentIndex = document.location.pathname
    .toString()
    .split("/")
    .findIndex((id) => id === props.collapse.id);
  if (currentIndex > -1) {
    navItemClass = [...navItemClass, "active"];
    if (props.layout !== "horizontal") {
      navLinkClass = [...navLinkClass, "active"];
    }
  }

  const subContent = (
    <>
      <Link
        to={components.BLANK_LINK}
        className={navLinkClass.join(" ")}
        onClick={() => props.onCollapseToggle(props.collapse.id, props.type)}
      >
        <NavIcon items={props.collapse} />
        {itemTitle}
        <NavBadge layout={props.layout} items={props.collapse} />
      </Link>
      <ul className="pcoded-submenu">{navItems}</ul>
    </>
  );
  let mainContent = "";
  if (props.layout === "horizontal") {
    mainContent = (
      <li
        className={navItemClass.join(" ")}
        onMouseLeave={() =>
          props.onNavCollapseLeave(props.collapse.id, props.type)
        }
        onMouseEnter={() =>
          props.onCollapseToggle(props.collapse.id, props.type)
        }
      >
        {subContent}
      </li>
    );
  } else {
    mainContent = <li className={navItemClass.join(" ")}>{subContent}</li>;
  }

  return <>{mainContent}</>;
}

const mapStateToProps = (state) => {
  // console.log('NavCollapse mapStateToProps ', state);
  return {
    layout: state.commonReducer.layout,
    isOpen: state.commonReducer.isOpen,
    isTrigger: state.commonReducer.isTrigger,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCollapseToggle: (id, type) =>
      dispatch({
        type: actionTypes.COLLAPSE_TOGGLE,
        menu: { id: id, type: type },
      }),
    onNavCollapseLeave: (id, type) =>
      dispatch({
        type: actionTypes.NAV_COLLAPSE_LEAVE,
        menu: { id: id, type: type },
      }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NavCollapse)
);
