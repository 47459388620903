import { bindActionCreators } from 'redux';
import { store } from './index';
// import { actions as authSaga } from 'store/modules/admin/AuthReducer';
// import { actions as historySaga } from 'store/modules/common/HistoryReducer';

import { actions as initSaga } from 'store/modules/common/ConfigReducer';

//hds
import { actions as serviceSaga } from 'store/modules/service/ServiceReducer';
import { actions as utilSaga } from 'store/modules/util/UtilReducer';
import { actions as authSaga } from 'store/modules/auth/AuthReducer';

import { actions as signinSaga } from 'store/modules/signin/SigninReducer';
import { actions as signupSaga } from 'store/modules/signup/SignupReducer';
import { actions as userSaga } from 'store/modules/user/UserReducer';

// import { actions as userInfoSaga } from 'store/modules/user/UserInfoReducer';

import { actions as appActions } from './modules/app';

export const { dispatch } = store;

// export const AuthActions = bindActionCreators(authSaga, dispatch);
// export const HistoryActions = bindActionCreators(historySaga, dispatch);

export const InitActions = bindActionCreators(initSaga, dispatch);

//hds
// export const UserInfoAction = bindActionCreators(userInfoSaga, dispatch);

//Auth
export const AuthActions = bindActionCreators(authSaga, dispatch);

export const SignupActions = bindActionCreators(signupSaga, dispatch);
export const SigninActions = bindActionCreators(signinSaga, dispatch);
//Service
export const ServiceAction = bindActionCreators(serviceSaga, dispatch);
//User
export const UserAction = bindActionCreators(userSaga, dispatch);
//Util
export const UtilActions = bindActionCreators(utilSaga, dispatch);

export const AppActions = bindActionCreators(appActions, dispatch);
