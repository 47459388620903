import { createAction } from '@reduxjs/toolkit';
import { put, call, takeEvery } from 'redux-saga/effects';
import CODE from 'lib/codeName';

// action type 별 생성
export function createFetchAction(type) {
  const INIT = `${type}_INIT`;
  const REQUEST = `${type}_REQUEST`;
  const SUCCESS = `${type}_SUCCESS`;
  const FAILURE = `${type}_FAILURE`;

  return {
    INIT: createAction(INIT),
    REQUEST: createAction(REQUEST),
    SUCCESS: createAction(SUCCESS),
    FAILURE: createAction(FAILURE),
  };
}

// saga 공통 패턴
export const createPromiseSaga = (type, promiseCreator) => {
  // console.log('createPromiseSaga ', type);
  const [INIT, SUCCESS, FAILURE] = [`${type}_INIT`, `${type}_SUCCESS`, `${type}_FAILURE`];
  return function* saga(action) {
    const param = action.payload;
    const id = action.meta;
    yield put({ type: INIT, payload: param });
    try {
      // 재사용성을 위하여 promiseCreator 의 파라미터엔 action.payload 값을 넣도록 설정합니다.
      const payload = yield call(promiseCreator, action.payload);
      if (
        payload.status == CODE.status.success ||
        payload.status == CODE.status.created ||
        payload.status == CODE.status.accepted
      ) {
        yield put({ type: SUCCESS, payload });
      } else {
        yield put({ type: FAILURE, error: true, payload: payload });
      }
    } catch (e) {
      yield put({ type: FAILURE, error: true, payload: e });
    }
  };
};
