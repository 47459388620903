import React, {Component} from 'react';
import windowSize from 'react-window-size';


import components from "store/constant";
import { useState } from 'react';

function NavSearch(props) {
    const [state, setState] = React.useState({
        searchWidth: (props.windowWidth < 992) ? 90 : 0,
        searchString: (props.windowWidth < 992) ? '90px' : '',
        isOpen: (props.windowWidth < 992)
    });

    const searchOnHandler = () => {
        setState({isOpen: true});
        const searchInterval = setInterval(() => {
            if (state.searchWidth >= 91) {
                clearInterval(searchInterval);
                return false;
            }
            setState(prevSate => {
                return {
                    searchWidth: prevSate.searchWidth + 15,
                    searchString: prevSate.searchWidth + 'px'
                }
            });
        }, 35);
    };

    const searchOffHandler = () => {
        const searchInterval = setInterval(() => {
            if (state.searchWidth < 0) {
                setState({isOpen: false});
                clearInterval(searchInterval);
                return false;
            }
            setState(prevSate => {
                return {
                    searchWidth: prevSate.searchWidth - 15,
                    searchString: prevSate.searchWidth + 'px'
                }
            });
        }, 35);
    };

    let searchClass = ['main-search'];
    if (state.isOpen) {
        searchClass = [...searchClass, 'open'];
    }

    return (
        <>
            <div id="main-search" className={searchClass.join(' ')}>
                <div className="input-group">
                    <input type="text" id="m-search" className="form-control" placeholder="Search . . ." style={{width: state.searchString}}/>
                    <a href={components.BLANK_LINK} className="input-group-append search-close" onClick={searchOffHandler}>
                        <i className="feather icon-x input-group-text"/>
                    </a>
                    <span className="input-group-append search-btn btn btn-primary" onClick={searchOnHandler}>
                    <i className="feather icon-search input-group-text"/>
                </span>
                </div>
            </div>
        </>
    );

}

export default NavSearch;