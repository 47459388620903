import React, {
  useEffect,
  useRef,
  useReducer,
  useState,
  useCallback,
} from 'react';
import _ from 'lodash';
import { useSelector, shallowEqual } from 'react-redux';
import { css } from 'styled-components';
import { store } from 'react-notifications-component';
import crypto from 'crypto';
import cryptoJS from 'crypto-js';
import uuid from 'react-uuid';
import CODE from 'lib/codeName';
import { whiteListRedirectUrl } from 'lib/mapper';
import { TurnedInRounded } from '@material-ui/icons';

const GB_STANDARD = 1024 * 1024 * 1024;
const MB_STANDARD = 1024 * 1024;
const KB_STANDARD = 1024;

const moment = require('moment');

export const keys = {
  resent_login: '_dof_auth_recent_login',
  auto_login: '_dof_auto_login',
};

export const storageConvert = storage => {
  if (storage > GB_STANDARD) {
    return (storage / GB_STANDARD).toFixed(2) + 'GB';
  } else if (storage > MB_STANDARD) {
    return (storage / MB_STANDARD).toFixed(2) + 'MB';
  } else {
    return (storage / KB_STANDARD).toFixed(2) + 'KB';
  }
};

export const globalNotification = notifyData => {
  store.addNotification({
    title: notifyData.title,
    message: notifyData.content,
    type: notifyData.type,
    insert: 'top',
    container: 'top-center',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 3000,
      //   onScreen: true
    },
  });
};

export const tempPasswordGenerator = (textCount, numCount) => {
  var tempPassword = '';
  var textPossible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  var numberPossible = '0123456789';

  for (var i = 0; i < textCount; i++)
    tempPassword += textPossible.charAt(
      Math.floor(Math.random() * textPossible.length),
    );
  for (var i = 0; i < numCount; i++)
    tempPassword += numberPossible.charAt(
      Math.floor(Math.random() * numberPossible.length),
    );

  return tempPassword;
};

export const authRemove = () => {
  console.log('local storage auth remove call');
  window.localStorage.removeItem('auth');
};

export const authGet = () => {
  // console.log("local storage auth get call");
  return window.localStorage.getItem('auth');
};

/**
 * 정규식 이메일 유효성 검사
 * @param {string} value
 */
export const regEmail = value => {
  if (checkSpace(value)) return false;
  let regExp =
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
  return regExp.test(value);
};
/**
 * 공백있나 없나 체크
 * @param {string} value
 */
export function checkSpace(value) {
  return value && value.search(/\s/) !== -1;
}

/**
 * 정규식 비밀번호 유효성 검사
 * 8~16자리 글자, 영어,대문자소문자,특수문자
 * @param {string} value
 */
export const regPassword = value => {
  if (checkSpace(value)) return false;
  let regExp = /^.*(?=^.{8,16}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=-_]).*$/;
  return regExp.test(value);
};

/**
 * 숫자만
 * @param {number} value
 */
export function regNumber(value) {
  if (typeof value !== 'number' && !value) return false;
  if (checkSpace(value)) return false;
  let regExp = /^[0-9]*$/g;
  // let regExp = /^[\d]$/g;
  return regExp.test(value);
}

/**
 * URL Domain 정규식
 * @param {string} value
 */
export function regUrl(value) {
  if (checkSpace(value)) return false;
  let regExp = /(http(s)?:\/\/)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}/gi;
  return regExp.test(value);
}

/**
 * localstorage 아이템 getter
 */
export function getLocalStorageItem(name) {
  console.log('get [' + name + '] item from localstorage');
  return window.localStorage.getItem(name);
}

/**
 * localstorage 아이템 setter
 */
export function setLocalStorageItem(name, value) {
  console.log('set [' + name + '] item at localstorage');
  window.localStorage.setItem(name, value);
}

/**
 * localstorage 아이템 remover
 */
export function removeLocalStorageItem(name) {
  // console.log("remove [" + name + "] item from localstorage");
  window.localStorage.removeItem(name);
}

/**
 * sessionStorage 아이템 getter
 */
export function getSessionStorageItem(name) {
  // console.log("get [" + name + "] item from sessionStorage");
  return window.sessionStorage.getItem(name);
}

/**
 * sessionStorage 아이템 setter
 */
export function setSessionStorageItem(name, value) {
  // console.log('set [' + name + '] item at sessionStorage');
  window.sessionStorage.setItem(name, value);
}

/**
 * sessionStorage 아이템 remover
 */
export function removeSessionStorageItem(name) {
  // console.log('remove [' + name + '] item from sessionStorage');
  window.sessionStorage.removeItem(name);
}

/**
 * stssionStorage 아이템 clear
 */
export function clearSessionStorageItem() {
  console.log('clear item from sessionStorage');
  window.sessionStorage.clear();
}

export function makeServicKey(length) {
  var serviceKey = '';
  var characters =
    '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ~`!@#$%^&*()-+|_=[]{}<>?/.;';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    serviceKey += characters.charAt(
      Math.floor(Math.random() * charactersLength),
    );
  }
  return serviceKey;
}

export function getParameterByNameWithPlusStr(search, name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
    results = regex.exec(search);
  return results == null ? '' : decodeURIComponent(results[1]);
}

export function getParameterByName(url, name) {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

/**
 * DidUpdateMount를 구현한 Custom hooks, useEffect를 쓰면 한번 읽히는 것을 방지하기 위한 최적화 작업
 * @param {*} fn
 * @param {*} inputs
 */
export function useDidUpdateEffect(fn, inputs) {
  const didMountRef = useRef(false);
  useEffect(() => {
    if (didMountRef.current) {
      fn();
    } else {
      didMountRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, inputs);
}

// NOTE: shallowEqual
export function useShallowSelector(fn) {
  return useSelector(fn, shallowEqual);
}

// default : width + marginRight = 60
export const beforeDash = ({
  width = 45,
  height = 4,
  marginRight = 15,
  backgroundColor = `#1da7e0`,
  fontSize = 22,
}) => {
  return css`
    display: flex;
    align-items: center;
    font-size: ${`${fontSize}px`};
    &:before {
      content: '';
      width: ${`${width}px`};
      height: ${`${height}px`};
      margin-right: ${`${marginRight}px`};
      background-color: ${backgroundColor};
    }
  `;
};

export function getCookie(name) {
  let matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' +
        name?.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
        '=([^;]*)',
    ),
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}
// 1h * 시간 * 날짜
const maxAge = 3600 * 24 * 7;
export function setCookie(name, value, options = {}) {
  // console.log('options', options);
  options = {
    path: '/',
    // 필요한 경우, 옵션 기본값을 설정할 수도 있습니다.
    'max-age': maxAge,
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie =
    encodeURIComponent(name) + '=' + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += '; ' + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += '=' + optionValue;
    }
  }

  document.cookie = updatedCookie;
}

export function encodedByAES56(data) {
  const obj = {
    password: data,
    uuid: uuid(),
  };
  // console.log('encodedByAES56 _____ ', obj);
  const rk = process.env.REACT_APP_JWT_SECRET.padEnd(32, ' ');
  const cipher = cryptoJS.AES.encrypt(
    JSON.stringify(obj),
    cryptoJS.enc.Utf8.parse(rk),
    {
      iv: cryptoJS.enc.Utf8.parse(''),
      padding: cryptoJS.pad.Pkcs7,
      mode: cryptoJS.mode.CBC,
    },
  );
  return cipher.toString();
}

export function checkIsSecureUrl(url) {
  // if (!regUrl(url)) return false;
  const whiteList = process.env.REACT_APP_API_URL_WHITELIST.split(',');
  // let isSecure = false;
  // console.log('whiteList ______ ', whiteList);
  console.log('url ______ ', url);
  const urlData = new URL(url);
  console.log('urlData ______ ', urlData);
  // const isSecure = whiteList.find(item => item === urlData.origin);
  const isUrl = whiteList.find(item => item === urlData.origin);
  const isNotUrl = whiteList.find(item => item === url);

  if (!isUrl && !isNotUrl) {
    // isSecure = false;
    return false;
  } else {
    // isSecure = true;
    return true;
  }

  // return isSecure;
}
/**
 * Email Domain 정규식
 */
export const regEmailDomain = value => {
  if (checkSpace(value)) return false;
  let regExp = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
  return regExp.test(value);
};
