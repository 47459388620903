import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import windowSize from 'react-window-size';

import NavGroup from 'App/layout/AdminLayout/Navigation/NavContent/NavGroup';
import components from 'store/constant';
import * as actionTypes from 'store/actions';

function NavContent(props) {
  const [state, setState] = React.useState({
    scrollWidth: 0,
    prevDisable: true,
    nextDisable: false,
  });

  const scrollPrevHandler = () => {
    const wrapperWidth = document.getElementById('sidenav-wrapper').clientWidth;

    let scrollWidth = state.scrollWidth - wrapperWidth;
    if (scrollWidth < 0) {
      setState({ scrollWidth: 0, prevDisable: true, nextDisable: false });
    } else {
      setState({ scrollWidth: scrollWidth, prevDisable: false });
    }
  };

  const scrollNextHandler = () => {
    const wrapperWidth = document.getElementById('sidenav-wrapper').clientWidth;
    const contentWidth = document.getElementById('sidenav-horizontal').clientWidth;

    let scrollWidth = state.scrollWidth + (wrapperWidth - 80);
    if (scrollWidth > contentWidth - wrapperWidth) {
      scrollWidth = contentWidth - wrapperWidth + 80;
      setState({
        scrollWidth: scrollWidth,
        prevDisable: false,
        nextDisable: true,
      });
    } else {
      setState({ scrollWidth: scrollWidth, prevDisable: false });
    }
  };
  const navItems = props.navigation.map(item => {
    switch (item.type) {
      case 'group':
        return <NavGroup layout={props.layout} key={item.id} group={item} />;
      default:
        return false;
    }
  });

  let mainContent = '';
  if (props.layout === 'horizontal') {
    let prevClass = ['sidenav-horizontal-prev'];
    if (state.prevDisable) {
      prevClass = [...prevClass, 'disabled'];
    }
    let nextClass = ['sidenav-horizontal-next'];
    if (state.nextDisable) {
      nextClass = [...nextClass, 'disabled'];
    }

    mainContent = (
      <div className="navbar-content sidenav-horizontal" id="layout-sidenav">
        {/* <a href={components.BLANK_LINK} className={prevClass.join(' ')} onClick={scrollPrevHandler}><span/></a> */}
        <Link
          to={components.BLANK_LINK}
          className={prevClass.join(' ')}
          onClick={scrollPrevHandler}
        >
          <span />
        </Link>
        <div id="sidenav-wrapper" className="sidenav-horizontal-wrapper">
          <ul
            id="sidenav-horizontal"
            className="nav pcoded-inner-navbar sidenav-inner"
            onMouseLeave={props.onNavContentLeave}
            style={{ marginLeft: '-' + state.scrollWidth + 'px' }}
          >
            {navItems}
          </ul>
        </div>
        <a href={components.BLANK_LINK} className={nextClass.join(' ')} onClick={scrollNextHandler}>
          <span />
        </a>
      </div>
    );
  } else {
    mainContent = (
      <div className="navbar-content datta-scroll">
        <PerfectScrollbar>
          <ul className="nav pcoded-inner-navbar">{navItems}</ul>
        </PerfectScrollbar>
      </div>
    );
  }

  return <>{mainContent}</>;
}

const mapStateToProps = state => {
  return {
    layout: state.commonReducer.layout,
    collapseMenu: state.commonReducer.collapseMenu,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onNavContentLeave: () => dispatch({ type: actionTypes.NAV_CONTENT_LEAVE }),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavContent));
