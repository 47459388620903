import { handleActions } from 'redux-actions';
import produce from 'immer';
import { createAction, createReducer, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import CODE from 'lib/codeName';
import { all, takeEvery } from 'redux-saga/effects';
import { createFetchAction, createPromiseSaga } from 'store/util';
import { fetchInitCountryList, fetchRegionList, fetchLanguageList, fetchLegalList } from 'api/init';

export const INIT_COUNTRY_LIST = createFetchAction('init/INIT_COUNTRY_LIST');
export const REGION_LIST = createFetchAction('init/REGION_LIST');
export const LANGUAGE_LIST = createFetchAction('init/LANGUAGE_LIST');
export const LEGAL_LIST = createFetchAction('init/LEGAL_LIST');

const initialState = {
  languageList: [],
  countryList: [],
  regionList: [],
  legalList: [],
};

const slice = createSlice({
  name: 'init',
  initialState,
  reducers: {
    INIT_COUNTRY_LIST_REQUEST: (state, { payload }) => {
      console.log('INIT_COUNTRY_REQUEST', payload);
    },
    INIT_COUNTRY_LIST_SUCCESS: (state, { payload }) => {
      console.log('INIT_COUNTRY_SUCCESS', payload);
      state.countryList = payload.data.countryList;
    },
    REGION_LIST_REQUEST: (state, { payload }) => {
      console.log('REGION_LIST_REQUEST', payload);
    },
    REGION_LIST_SUCCESS: (state, { payload }) => {
      console.log('REGION_LIST_SUCCESS', payload);
      state.regionList = payload.data.regionList;
    },
    LANGUAGE_LIST_REQUEST: (state, { payload }) => {
      console.log('LANGUAGE_LIST_REQUEST', payload);
    },
    LANGUAGE_LIST_SUCCESS: (state, { payload }) => {
      console.log('INIT_COUNTRY_LIST_SUCCESS', payload);
      state.languageList = payload.data.languageList;
    },
    LEGAL_LIST_REQUEST: (state, { payload }) => {
      console.log('LEGAL_LIST_REQUEST', payload);
    },
    LEGAL_LIST_SUCCESS: (state, { payload }) => {
      console.log('INIT_LEGAL_LIST_SUCCESS', payload);
      state.legalList = payload.data.agreementList;
    },
  },
});

const initCountryListSaga = createPromiseSaga('init/INIT_COUNTRY_LIST', fetchInitCountryList);
const regionListSaga = createPromiseSaga('init/REGION_LIST', fetchRegionList);
const languageListSaga = createPromiseSaga('init/LANGUAGE_LIST', fetchLanguageList);
const legalListSaga = createPromiseSaga('init/LEGAL_LIST', fetchLegalList);

export const actions = slice.actions;

export function* initSaga() {
  yield all([
    takeEvery(INIT_COUNTRY_LIST.REQUEST, initCountryListSaga),
    takeEvery(REGION_LIST.REQUEST, regionListSaga),
    takeEvery(LANGUAGE_LIST.REQUEST, languageListSaga),
    takeEvery(LEGAL_LIST.REQUEST, legalListSaga),
  ]);
}

export default slice.reducer;
