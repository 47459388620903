import { axs } from 'api/config/axiosConfig';
import apiUrl from 'lib/url';

export function fetchInitCountryList(payload) {
  console.log('fetchInitCountry');
  return axs({ url: apiUrl.COUNTRY_LIST, method: 'get', params: payload });
}

export function fetchCountryList(payload) {
  console.log('fetchInitCountry');
  return axs({ url: apiUrl.COUNTRY_LIST, method: 'get', params: payload });
}

export function fetchRegionList(payload) {
  console.log('fetchRegion');
  return axs({ url: apiUrl.REGION_LIST, method: 'get', params: payload });
}

export function fetchLanguageList(payload) {
  console.log('HDS] fetchLanguageList ____');
  return axs({ url: apiUrl.LANGUAGE_LIST, method: 'get', params: payload });
}

export function fetchLegalList(payload) {
  console.log('fetchLegalList');
  return axs({ url: apiUrl.LEGAL_LIST, method: 'get', params: payload });
}

export function uploadProfileImage(payload) {
  console.log('[API_V2]_[ uploadProfileImage ] : ', payload);
  return axs({
    url: apiUrl.UPLOAD_PROFILE_IMAGE,
    method: 'post',
    data: payload,
  });
}

export function completeProfileImage(payload) {
  console.log('[API_V2]_[ completeProfileImage ] : ', payload);
  return axs({
    url: apiUrl.UPLOAD_PROFILE_IMAGE_COMPLETE,
    method: 'put',
    data: payload,
  });
}

export function deleteProfileImage(payload) {
  console.log('[API_V2]_[ deleteProfileImage ] : ', payload);
  return axs({
    url: apiUrl.DELTE_PROFILE_IMAGE,
    method: 'delete',
    data: payload,
  });
}

export function fetchSurveyList(payload) {
  console.log('fetchSurveyList');
  return axs({
    url: apiUrl.SURVEY_LIST,
    method: 'get',
    params: payload,
  });
}

export function fetchLanguageTranslation(language) {
  console.log('HDS] fetchLanguageTranslation _________ ', language);
  return axs({
    url: `${apiUrl.LANGUAGE_TRANSLATION}/${language}`,
    method: 'get',
  });
}
