export default {
  items: [
    {
      id: 'navigation',
      //   title: "DOF Auth",
      title: 'SERVICE_NAME',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'authHome',
          //   title: "Home",
          title: 'GLOBAL_HOME',
          type: 'item',
          url: '/auth/home',
          icon: 'feather icon-home',
        },
        {
          id: 'myPage',
          title: 'GLOBAL_MYPAGE',
          type: 'item',
          url: '/auth/mypage',
          icon: 'feather icon-user',
        },
        {
          id: 'passwordChange',
          title: 'GLOBAL_PASSWORD_CHANGE',
          type: 'item',
          url: '/auth/password/change',
          icon: 'feather icon-lock',
        },
        // {
        //   id: 'linkedServiceList',
        //   title: 'GLOBAL_LINKED_SERVICE',
        //   type: 'item',
        //   url: '/auth/linked/service',
        //   icon: 'feather icon-list',
        // },
        {
          id: 'logout',
          title: 'GLOBAL_LOGOUT',
          type: 'item',
          url: '/auth/logout',
          icon: 'feather icon-log-out',
        },
      ],
    },
    // {
    //     id: 'ui-forms',
    //     title: 'User Manage',
    //     type: 'group',
    //     icon: 'icon-group',
    //     children: [
    //         {
    //             id: 'userlist',
    //             title: 'User List',
    //             type: 'item',
    //             icon: 'feather icon-server',
    //             url: '/user/list'
    //         },
    //         {
    //             id: 'qna-list',
    //             title: 'QnA List',
    //             type: 'item',
    //             icon: 'feather icon-server',
    //             url: '/qna/list'
    //         },
    //         // {
    //         //     id: 'userdetail',
    //         //     title: 'User Detail',
    //         //     type: 'item',
    //         //     icon: 'feather icon-server',
    //         //     url: '/user/detail/:userCode'
    //         // },
    //         // {
    //         //     id: 'form-basic',
    //         //     title: 'Form Elements',
    //         //     type: 'item',
    //         //     url: '/forms/form-basic',
    //         //     icon: 'feather icon-file-text'
    //         // },
    //        ` // {
    //         //     id: 'bootstrap',
    //         //     title: 'Table',
    //         //     type: 'item',
    //         //     icon: 'feather icon-server',
    //         //     url: '/tables/bootstrap'
    //         // }`
    //     ]
    // },
    // {
    //     id: 'purchase-mng',
    //     title: 'Purchase manage',
    //     type: 'group',
    //     icon: 'icon-charts',
    //     children: [
    //         {
    //             id: 'order-list',
    //             title: 'Order List',
    //             type: 'item',
    //             icon: 'feather icon-server',
    //             url: '/store/order/list'
    //         },
    //         {
    //             id: 'warranty-list',
    //             title: 'Warranty List',
    //             type: 'item',
    //             icon: 'feather icon-server',
    //             url: '/store/warranty/list'
    //         },
    //         // {
    //         //     id: 'purchase-detail',
    //         //     title: 'Purchase Detail',
    //         //     type: 'item',
    //         //     icon: 'feather icon-server',
    //         //     url: '/purchase/detail'
    //         // },
    //         // {
    //         //     id: 'charts',
    //         //     title: 'Charts',
    //         //     type: 'item',
    //         //     icon: 'feather icon-pie-chart',
    //         //     url: '/charts/nvd3'
    //         // },
    //         // {
    //         //     id: 'maps',
    //         //     title: 'Map',
    //         //     type: 'item',
    //         //     icon: 'feather icon-map',
    //         //     url: '/maps/google-map'
    //         // }
    //     ]
    // },
    // {
    //     id: 'product-mng',
    //     title: 'Product manage',
    //     type: 'group',
    //     icon: 'icon-charts',
    //     children: [
    //         {
    //             id: 'product-list',
    //             title: 'Product List',
    //             type: 'item',
    //             icon: 'feather icon-server',
    //             url: '/store/product/list'
    //         },
    //         {
    //             id: 'product-new',
    //             title: 'Product New',
    //             type: 'item',
    //             icon: 'feather icon-server',
    //             url: '/store/product/new'
    //         },
    //         {
    //             id: 'currency-new',
    //             title: 'Currency New',
    //             type: 'item',
    //             icon: 'feather icon-server',
    //             url: '/store/currency/new'
    //         },
    //         // {
    //         //     id: 'product-detail',
    //         //     title: 'Product Detail',
    //         //     type: 'item',
    //         //     icon: 'feather icon-server',
    //         //     url: '/store/product/detail'
    //         // },
    //         // {
    //         //     id: 'charts',
    //         //     title: 'Charts',
    //         //     type: 'item',
    //         //     icon: 'feather icon-pie-chart',
    //         //     url: '/charts/nvd3'
    //         // },
    //         // {
    //         //     id: 'maps',
    //         //     title: 'Map',
    //         //     type: 'item',
    //         //     icon: 'feather icon-map',
    //         //     url: '/maps/google-map'
    //         // }
    //     ]
    // },
    // {
    //     id: 'project-mng',
    //     title: 'Project manage',
    //     type: 'group',
    //     icon: 'icon-charts',
    //     children: [
    //         {
    //             id: 'prject-list',
    //             title: 'Project List',
    //             type: 'item',
    //             icon: 'feather icon-pie-chart',
    //             url: '/project/list'
    //         }
    //         // {
    //         //     id: 'charts',
    //         //     title: 'Charts',
    //         //     type: 'item',
    //         //     icon: 'feather icon-pie-chart',
    //         //     url: '/charts/nvd3'
    //         // },
    //         // {
    //         //     id: 'maps',
    //         //     title: 'Map',
    //         //     type: 'item',
    //         //     icon: 'feather icon-map',
    //         //     url: '/maps/google-map'
    //         // }
    //     ]
    // },
    // {
    //     id: 'admin-mng',
    //     title: 'Admin manage',
    //     type: 'group',
    //     icon: 'icon-charts',
    //     children: [
    //         {
    //             id: 'admin-list',
    //             title: 'Admin List',
    //             type: 'item',
    //             icon: 'feather icon-pie-chart',
    //             url: '/list'
    //         },
    //         {
    //             id: 'admin-new',
    //             title: 'Admin New',
    //             type: 'item',
    //             icon: 'feather icon-pie-chart',
    //             url: '/new'
    //         }
    //     ]
    // },
    // {
    //     id: 'dev-manage',
    //     title: 'Develop Manage',
    //     type: 'group',
    //     icon: 'icon-group',
    //     children: [
    //         {
    //             id: 'logList',
    //             title: 'Log List',
    //             type: 'item',
    //             icon: 'feather icon-server',
    //             url: '/log/list'
    //         }
    //     ]
    // }
    // {
    //     id: 'pages',
    //     title: 'Pages',
    //     type: 'group',
    //     icon: 'icon-pages',
    //     children: [
    //         {
    //             id: 'auth',
    //             title: 'Authentication',
    //             type: 'collapse',
    //             icon: 'feather icon-lock',
    //             badge: {
    //                 title: 'New',
    //                 type: 'label-danger'
    //             },
    //             children: [
    //                 {
    //                     id: 'signup-1',
    //                     title: 'Sign up',
    //                     type: 'item',
    //                     url: '/auth/signup-1',
    //                     target: false,
    //                     breadcrumbs: false
    //                 },
    //                 {
    //                     id: 'signin-1',
    //                     title: 'Sign in',
    //                     type: 'item',
    //                     url: '/auth/signin-1',
    //                     target: false,
    //                     breadcrumbs: false
    //                 }
    //             ]
    //         },

    //         {
    //             id: 'sample-page',
    //             title: 'Sample Page',
    //             type: 'item',
    //             url: '/sample-page',
    //             classes: 'nav-item',
    //             icon: 'feather icon-sidebar'
    //         },
    //         {
    //             id: 'docs',
    //             title: 'Documentation',
    //             type: 'item',
    //             url: '/docs',
    //             classes: 'nav-item',
    //             icon: 'feather icon-help-circle'
    //         },
    //         {
    //             id: 'menu-level',
    //             title: 'Menu Levels',
    //             type: 'collapse',
    //             icon: 'feather icon-menu',
    //             children: [
    //                 {
    //                     id: 'menu-level-1.1',
    //                     title: 'Menu Level 1.1',
    //                     type: 'item',
    //                     url: '#!',
    //                 },
    //                 {
    //                     id: 'menu-level-1.2',
    //                     title: 'Menu Level 2.2',
    //                     type: 'collapse',
    //                     children: [
    //                         {
    //                             id: 'menu-level-2.1',
    //                             title: 'Menu Level 2.1',
    //                             type: 'item',
    //                             url: '#',
    //                         },
    //                         {
    //                             id: 'menu-level-2.2',
    //                             title: 'Menu Level 2.2',
    //                             type: 'collapse',
    //                             children: [
    //                                 {
    //                                     id: 'menu-level-3.1',
    //                                     title: 'Menu Level 3.1',
    //                                     type: 'item',
    //                                     url: '#',
    //                                 },
    //                                 {
    //                                     id: 'menu-level-3.2',
    //                                     title: 'Menu Level 3.2',
    //                                     type: 'item',
    //                                     url: '#',
    //                                 }
    //                             ]
    //                         }
    //                     ]
    //                 }
    //             ]
    //         },
    //         {
    //             id: 'disabled-menu',
    //             title: 'Disabled Menu',
    //             type: 'item',
    //             url: '#',
    //             classes: 'nav-item disabled',
    //             icon: 'feather icon-power'
    //         },
    //         /*{
    //             id: 'buy-now',
    //             title: 'Buy Now',
    //             type: 'item',
    //             icon: 'feather icon-user',
    //             classes: 'nav-item',
    //             url: 'https://codedthemes.com',
    //             target: true,
    //             external: true,
    //             badge: {
    //                 title: 'v1.0',
    //                 type: 'label-primary'
    //             }
    //         }*/
    //     ]
    // },{
    //     id: 'ui-element',
    //     title: 'UI ELEMENT',
    //     type: 'group',
    //     icon: 'icon-ui',
    //     children: [
    //         {
    //             id: 'basic',
    //             title: 'Component',
    //             type: 'collapse',
    //             icon: 'feather icon-box',
    //             children: [
    //                 {
    //                     id: 'button',
    //                     title: 'Button',
    //                     type: 'item',
    //                     url: '/basic/button'
    //                 },
    //                 {
    //                     id: 'badges',
    //                     title: 'Badges',
    //                     type: 'item',
    //                     url: '/basic/badges'
    //                 },
    //                 {
    //                     id: 'breadcrumb-pagination',
    //                     title: 'Breadcrumb & Pagination',
    //                     type: 'item',
    //                     url: '/basic/breadcrumb-paging'
    //                 },
    //                 {
    //                     id: 'collapse',
    //                     title: 'Collapse',
    //                     type: 'item',
    //                     url: '/basic/collapse'
    //                 },
    //                 {
    //                     id: 'tabs-pills',
    //                     title: 'Tabs & Pills',
    //                     type: 'item',
    //                     url: '/basic/tabs-pills'
    //                 },
    //                 {
    //                     id: 'typography',
    //                     title: 'Typography',
    //                     type: 'item',
    //                     url: '/basic/typography'
    //                 }
    //             ]
    //         }
    //     ]
    // }
  ],
};
