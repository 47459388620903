import React from 'react';
import NavCollapse from 'App/layout/AdminLayout/Navigation/NavContent/NavCollapse';
import NavItem from 'App/layout/AdminLayout/Navigation/NavContent/NavItem';

import T from 'components/Common/text/T';
function navGroup(props) {
  let navItems = '';
  if (props.group.children) {
    const groups = props.group.children;
    navItems = Object.keys(groups).map(item => {
      item = groups[item];
      switch (item.type) {
        case 'collapse':
          return <NavCollapse key={item.id} collapse={item} type="main" />;
        case 'item':
          return <NavItem layout={props.layout} key={item.id} item={item} />;
        default:
          return false;
      }
    });
  }

  return (
    <>
      <li key={props.group.id} className="nav-item pcoded-menu-caption">
        <label>
          <T>{props.group.title}</T>
        </label>
      </li>
      {navItems}
    </>
  );
}

export default navGroup;
