import CustomText from 'components/Common/text/CustomText';
import T from 'components/Common/text/T';
import usePreviousLocation from 'lib/hooks/usePreviousLocation';
import {
  BREAK_POINT_MOBILE_MAX,
  BREAK_POINT_PC,
  BREAK_POINT_TABLET,
  BREAK_POINT_TABLET_MAX,
} from 'lib/mapper';
import { useDidUpdateEffect } from 'lib/util';
import React, { Suspense, useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { dof_logo_small, icon_prev } from 'static';
import styled from 'styled-components';
import { color, colors } from 'styles/utils';

function LoginLayout({ backUrl }) {
  const previousLocation = usePreviousLocation();
  const history = useHistory();

  return (
    <StyledLoginLayout>
      <div className="loginInfo__header_container">
        <div className="loginInfo__header_box">
          <div className="prev_icon">
            <button
              style={{
                border: 0,
                backgroundColor: 'transparent',
                padding: 0,
                margin: 0,
              }}
              onClick={() => {
                if (!!previousLocation) {
                  history.goBack();
                } else {
                  window.location.href = backUrl;
                }
              }}
            >
              <img src={icon_prev} alt="icon_prev" width={20} height={20} />
            </button>
          </div>
          <div className="logo_box">
            <a href="https://doflab.com/ko/">
              <img
                // src="https://doflab.com/wp-content/uploads/2020/04/logo-1.png"
                src={dof_logo_small}
                className="loginInfo__container_logo"
                loading="lazy"
              />
            </a>
            <CustomText
              fontSize={16}
              fontColor={colors.contentPrimary}
              fontWeight={600}
              marginBottom={0}
            >
              <T>GLOBAL_HEADER_TITLE</T>
            </CustomText>
          </div>
        </div>
        <StyledLine />
        <div className="loginInfo__go_to_back_wrapper">
          {!!backUrl && (
            <div className="go_to_back_box">
              <button
                style={{
                  border: 0,
                  backgroundColor: 'transparent',
                  padding: 0,
                  margin: 0,
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: 10,
                }}
                onClick={() => {
                  if (!!previousLocation) {
                    history.goBack();
                  } else {
                    window.location.href = backUrl;
                  }
                }}
              >
                <img src={icon_prev} alt="icon_prev" width={20} height={20} />
                <CustomText
                  fontSize={16}
                  fontColor={colors.primary}
                  fontWeight={400}
                  marginBottom={0}
                >
                  <T>GLOBAL_BACK_PAGE</T>
                </CustomText>
              </button>
            </div>
          )}
        </div>
      </div>
    </StyledLoginLayout>
  );
}

const StyledLoginLayout = styled.div`
  .loginInfo__header_container {
    /* position: fixed; */
    background-color: white;
    width: 100%;
    top: 0;
    z-index: 9999;
    .logo_box {
      display: flex;
      align-items: center;
      column-gap: 11px;
      .loginInfo__container_logo {
        /* height: 44px; */
        width: 100px;
      }
    }
    .go_to_back_box {
      display: flex;
      align-items: center;
      column-gap: 10px;
    }
    @media only screen and (max-width: ${BREAK_POINT_MOBILE_MAX}) {
      .loginInfo__header_box {
        display: flex;
        max-width: ${BREAK_POINT_MOBILE_MAX};
        margin: 0 auto;
        padding: 0 20px;
        height: 60px;
        align-items: center;
        justify-content: center;
        .prev_icon {
          position: absolute;
          top: 15px;
          left: 20px;
        }
      }
      .loginInfo__go_to_back_wrapper {
        display: none;
      }
    }
    @media only screen and (min-width: ${BREAK_POINT_TABLET}) and (max-width: ${BREAK_POINT_TABLET_MAX}) {
      .loginInfo__header_box {
        display: flex;
        max-width: ${BREAK_POINT_TABLET_MAX};
        margin: 0 auto;
        padding: 0 20px;
        height: 60px;
        align-items: center;
        justify-content: center;
        .prev_icon {
          position: absolute;
          top: 15px;
          left: 20px;
        }
      }
      .loginInfo__go_to_back_wrapper {
        display: none;
      }
    }
    @media only screen and (min-width: ${BREAK_POINT_PC}) {
      .loginInfo__header_box {
        display: flex;
        max-width: ${BREAK_POINT_PC};
        margin: 0 auto;
        padding: 0 20px;
        height: 60px;
        align-items: center;
        .prev_icon {
          display: none;
        }
      }
      .loginInfo__go_to_back_wrapper {
        display: flex;
        max-width: ${BREAK_POINT_PC};
        height: 60px;
        margin: 0 auto;
        padding: 0 20px;
        align-items: center;
      }
    }
  }
`;

const StyledLine = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colors.borderLine};
`;

export default LoginLayout;
