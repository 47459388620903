import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ReactNotification from 'react-notifications-component';

import '../../node_modules/font-awesome/scss/font-awesome.scss';

import Loader from 'App/layout/Loader';

import ScrollToTop from 'App/layout/ScrollToTop';
import { SinglePageRoutes } from 'routes';
import AdminLayout from 'App/layout/AdminLayout';
import Core from 'containers/base/Core';

import 'lang/i18n';
import LoginLayout from './layout/LoginLayout';
import { GOOGLE_AUTH_CLIENT_ID } from 'lib/setting';
import { GoogleOAuthProvider } from '@react-oauth/google';

function App(props) {
  console.log('현재 URL', window.location.pathname);
  const Error = React.lazy(() => import('containers/error/Error'));

  const menu = SinglePageRoutes.map((route, index) => {
    return route.component ? (
      <Route
        key={index}
        path={route.path}
        exact={route.exact}
        name={route.name}
        render={props => <route.component {...props} />}
      />
    ) : null;
  });

  return (
    <GoogleOAuthProvider clientId={GOOGLE_AUTH_CLIENT_ID}>
      <ReactNotification />
      <ScrollToTop>
        <Suspense fallback={<Loader />}>
          <Core />
          <Switch>
            <Redirect exact from="/" to="login" />
            {menu}
            <AdminLayout />
            <Route path="*" to="login" />
            {/* <Route component={Error}/> */}
          </Switch>
        </Suspense>
      </ScrollToTop>
    </GoogleOAuthProvider>
  );
}

export default App;
